import React from "react";

import Button from "../../../atoms/ButtonV3";
import PageMain from "../../../atoms/PageMain";

import {png} from "../../../../assets/png";
import "./style.scss"

const HowToBuyTokenOne = () => {
    return (
        <PageMain page="how-to-buy-token_one"
                  title={<>How to buy <br/> <span> kompete token</span></>}
                  text="Looking to buy KOMPETE Token, but aren’t sure how? We’ve made it easy. Follow these three simple steps to secure your bag and maximize your fun!"
                  resizeImage={png.Hero2Resized}
                  originalImage={png.Hero2}
                  filter={true}
        >
            <div className="how-to-buy-token_one_buttons">
                <Button
                    onClick={() => window.open("https://discord.gg/wz5YrNtJqB", "_blank")}
                    view="secondary" icon="discordBlack"
                    path={true}
                >
                    Get help in discord
                </Button>
                <Button
                    onClick={() => window.open("https://t.me/KOMPETEgameportal", "_blank")}
                    view="secondary"
                    icon="telegramBlack"
                    path={true}>
                    Get help in telegram
                </Button>
            </div>
        </PageMain>
    )
}

export default HowToBuyTokenOne