import React from "react"
import Image from "../../../atoms/Image";
import UnderLine from "../../../atoms/UnderLine";

import {svg} from "../../../../assets/svg";
import "./style.scss"

const HowToBuyTokenFour = () => {
    return (
        <section className="how-to-buy-token_four">
            <div className="container how-to-buy-token_four_block">
                <h2 className="container how-to-buy-token_four_block_title">Need Help? <span>connect with <br/> us on socials!</span>
                </h2>
                <UnderLine/>
                <div className="container how-to-buy-token_four_block_socials">
                    <div>
                        <a name="telegram" href="https://t.me/KOMPETEgameportal" target="_blank" rel="noreferrer">
                            <Image alt="telegram" src={svg.Telegram}/>
                            <span>Telegram</span>
                        </a>
                    </div>
                    <div>
                        <a name="discord" href="https://discord.gg/wz5YrNtJqB" target="_blank" rel="noreferrer">
                            <Image alt="discord" src={svg.Discord}/>
                            <span>Discord</span>
                        </a>
                    </div>
                    <div>
                        <a name="twitter" href="https://twitter.com/KOMPETEgame" target="_blank" rel="noreferrer">
                            <Image alt="discord" src={svg.TwitterBlue}/>
                            <span>Twitter</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowToBuyTokenFour