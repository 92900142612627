import React from "react";
import UnderLine from "../UnderLine";
import "./style.scss"

const Title = ({underline, underTitle, children}) => {
    return (
        <div className="container-title">
            <h3>{children}</h3>
            {
                underline &&
                <UnderLine/>
            }
            {
                underTitle &&
                <p className="container-title_underTitle">{underTitle}</p>
            }
        </div>
    )
}
export default Title