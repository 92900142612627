import React from "react";
import useWindowSize from "../../../Hooks/useWindowSize";

import UnderLine from "../../atoms/UnderLine";
import Image from "../../atoms/Image";
import TokenERC20 from "./TokenERC20";

import "./style.scss"
import {png} from "../../../assets/png";

const TokenHero = () => {
    const {width} = useWindowSize()

    return (
        <section className="token-hero">
            <div className="container">
                <div className="flex">
                    <div className="description">
                        <h1>Kompete Token</h1>
                        <UnderLine/>
                        <p>A virtual currency that allows players to transact cosmetics with each other on the Bazaar, access closed
                            testing with the devs, and access exclusive cosmetics!</p>
                    </div>
                    <div className="thumb">
                        <Image alt="kompete-token" src={png.T1}/>
                    </div>
                </div>
                {width < 991 && <TokenERC20/>}
            </div>
        </section>
    )
}
export default TokenHero