import React from "react";
import PageMain from "../../atoms/PageMain";
import {png} from "../../../assets/png";
import "./style.scss"

const EconomyMain = () => {
    return (
        <PageMain page="economy_main"
                  title={<>A <span>player driven</span><br/> game economy</>}
                  text="Play for free with no pay to win. Earn and buy cosmetics. Buy & sell cosmetics with other players."
                  resizeImage={png.HeroEcoMainResized}
                  originalImage={png.HeroEcoMain}
                  filter={true}
        />
    )
}
export default EconomyMain