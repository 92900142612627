import React from "react";
import Chart from "../../molecules/Chart";
import Title from "../../atoms/TitleTemplate";
import "./style.scss"

const TokenFive = () => {

    return (
        <section className="token-five">
            <div className="container">
                <Title underline={true}>The <span>Chart</span></Title>
                <div className="chart">
                    <Chart/>
                </div>
            </div>
        </section>
    )
}

export default TokenFive