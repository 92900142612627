import React, {useEffect} from "react";
import RejectModal from "../../../../../components/molecules/TxhModals/RejectModal";

const StepWalletReject = ({show, changeStep}) => {

    useEffect(()=>{
        setTimeout(()=>{
            changeStep("step9")
        },3000)
    },[])

    return (
        <RejectModal
            show={show}
            text1="Ooops!"
            text2="Web3 Wallet failed to connect"
            isShowClose={false}
        />
    )
}

export default StepWalletReject