import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {reset} from "../../../../../Store/AuthV2/AuthV2Slice"

import ModalWindowTemplate from "../../../../../components/molecules/ModalWindowTemplate";
import Button from "../../../../../components/atoms/ButtonV3";

import "../style.scss"

const StepSignUpSuccess = ({show, changeStep}) => {
    const dispatch = useDispatch()
    const {socialLogin} = useSelector(state => state.authV2.userVerification)

    useEffect(() => {
        dispatch(reset("signUp"))
    }, [])

    return (
        <ModalWindowTemplate
            show={show}
            privacy={true}
            paddingTop={true}
            className="auth-step_step7"
            changeStep={changeStep}
            title={<span>Account Created!</span>}
        >
            <p className="auth-step_step7_text">Your account has been created successfully. To access your account, please log in
                below
            </p>
            <Button view="secondary" onClick={() => socialLogin ? changeStep("step1") : changeStep("step2")}>Login</Button>
        </ModalWindowTemplate>
    )
}

export default StepSignUpSuccess