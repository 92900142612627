import React, {memo} from "react"
import FooterModernize from "../../../components/atoms/ModernizeFooter";

import "./style.scss"
import FollowUsFooter from "../../../components/atoms/FollowUsFooter";
import classNames from "classnames";

const Footer = ({isFollowUsShow}) => {

    const footerStyle = classNames("footer", {"background": true})

    return (
        <footer className={footerStyle}>
            {
                isFollowUsShow &&
                <FollowUsFooter/>
            }
            <FooterModernize/>
        </footer>
    )
}

export default memo(Footer)