import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import parse from 'html-react-parser';

import {getCurrentPostContent,reset} from "../../../../Store/NewsPosts/postSlice";
import {FacebookShareButton, TelegramShareButton, TwitterShareButton} from "react-share";

import UnderLine from "../../../atoms/UnderLine";
import Image from "../../../atoms/Image";
import LoadSpinner from "../../../atoms/LoadSpinner";
import "./style.scss"

const Post = () => {
    const {currentPost, success, loading} = useSelector(state => state.posts.content)
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {id} = useParams();

    useEffect(() => {
        dispatch(getCurrentPostContent(id))
    }, [location.pathname])


    useEffect(() => {
        if (success && !currentPost) {
            navigate("/news")
            dispatch(reset())
        }
    }, [success])


    return (
        <section className="current-news-container_post container">
            {
                loading
                    ? <div className="spinner-container-allPage">
                        <LoadSpinner/>
                    </div>
                    : currentPost
                    ? <div className="current-news-container_post_content">
                        <div className="current-news-container_post_content_bd-wrap">
                            <h1>{currentPost?.title}</h1>
                            <UnderLine/>
                            <div className="current-news-container_post_content_bd-wrap_author">
                                By <span className="current-news-container_post_content_bd-wrap_author_name">
                                    {currentPost?.author.name}
                                </span>
                                <em>|</em>
                                {currentPost?.date}
                            </div>
                            <SocialShare currentPost={currentPost}/>
                            <div className="current-news-container_post_content_bd-wrap_thumb">
                                <Image alt="post" src={currentPost?.image?.url}/>
                            </div>
                            <div className="current-news-container_post_content_bd-wrap_content">
                                {success && currentPost && parse(currentPost?.content?.html)}
                            </div>
                            {
                                currentPost?.video &&
                                <div className="video-youtube">
                                    {parse(currentPost?.video?.html)}
                                </div>
                            }
                            <div className="current-news-container_post_content_bd-wrap_author">
                                By <span className="current-news-container_post_content_bd-wrap_author_name">
                                    {currentPost?.author.name}
                                </span>
                                <em>|</em>
                                {currentPost?.date}
                            </div>
                            <SocialShare currentPost={currentPost}/>
                        </div>
                    </div>
                    : <div></div>
            }
        </section>
    )
}

export default Post


const SocialShare = ({currentPost}) => {
    return (
        <div className="p-share">
            <TelegramShareButton
                className="telegram telegramShare"
                url={`${window.location.href}`}
            />
            <TwitterShareButton
                className="p-twitter twitterShare"
                url={`${window.location.href}`}
            />
            <FacebookShareButton
                url={`${window.location.href}`}
                quote={currentPost?.title}
                hashtag={"#KOMPETE"}
                className="p-facebook"
            />
        </div>
    )
}
