import React, {useState} from "react"
import ModalWindow from "../../atoms/ModalWindow";
import {svg} from "../../../assets/svg";
import "./style.scss"

const SuccessModal = ({text1, text2, show, isShowClose = true}) => {
    const [close, setClose] = useState(show)

    return (
        <ModalWindow show={close} type="window-lg" paddingTop={true} closeClick={isShowClose && setClose} isShowClose={isShowClose}>
            <div className="success-block">
                <div className="success-block_content">
                    <div className="success-block_content_image">
                        <img alt="success" src={svg.Success}/>
                    </div>
                    {
                        text1 &&
                        <p className="success-block_content_text1">{text1}</p>
                    }
                    {
                        text2 &&
                        <p className="success-block_content_text2">{text2}</p>
                    }
                </div>
            </div>
        </ModalWindow>
    )
}

export default SuccessModal