import React from "react";
import LoadSpinner from "./index";

const LoadSpinnerAllPage = ({show}) => {
    return (
        <React.Fragment>
            {
                show &&
                <div className="spinner-container-allPage">
                    <LoadSpinner/>
                </div>
            }
        </React.Fragment>
    )
}

export default LoadSpinnerAllPage