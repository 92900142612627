import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";

import {userSignIn, reset} from "../../../../../Store/AuthV2/AuthV2Slice";

import LoadSpinnerAllPage from "../../../../../components/atoms/LoadSpinner/LoadSpinnerAllPage";
import Input from "../../../../../components/atoms/InputV2";
import Button from "../../../../../components/atoms/ButtonV3";
import ModalWindowTemplate from "../../../../../components/molecules/ModalWindowTemplate";
import ChangeStepButtonTemplate from "../ReusableComponents/ChangeStepButtonTemplate";

import "../style.scss"

const StepSignInForm = ({show, changeStep, fieldHandle, stepData, checkFields}) => {
    const {signIn} = useSelector(state => state.authV2)
    const [error, setError] = useState("")
    const dispatch = useDispatch()

    useEffect(() => {
        if (signIn.error) {
            setError(signIn.errorMessage)
        }
    }, [signIn.error, signIn.errorMessage])

    const login = () => {
        const {email, password} = stepData.steps.step2.fields
        setError("")
        checkFields()

        if (!email.value || !password.value) {
            setError("Account details incorrect. Please enter again")
        } else {
            dispatch(reset("signIn"))
            dispatch(userSignIn({username: email.value, password: password.value}))
        }
    }

    return (
        <React.Fragment>
            <ModalWindowTemplate
                show={show}
                privacy={true}
                powered={true}
                paddingTop={true}
                changeStep={changeStep}
                title={<span>Login or create<br/>a KOMPETE Account</span>}
                className="auth-step_step2"
            >
                <Input
                    status={
                        stepData.steps.step2.fields.email.validation
                        || stepData.steps.step2.fields.password.validation
                        || signIn.error ? "error" : "default"
                    }
                    type="text"
                    label="email address"
                    name="email"
                    onChange={fieldHandle}
                    value={stepData.steps.step2.fields.email.value}
                    error={error}
                    placeholder="Email address"
                />
                <Input
                    status={stepData.steps.step2.fields.password.validation || signIn.error ? "error" : "default"}
                    type="password"
                    label="password"
                    name="password"
                    onChange={fieldHandle}
                    labelClickText="I forgot my password"
                    labelClick={() => changeStep("step3")}
                    value={stepData.steps.step2.fields.password.value}
                    placeholder="Password"
                />
                <Button view="secondary" onClick={login}>Sign in</Button>
                <ChangeStepButtonTemplate type="create" changeStep={changeStep} textLink={true}/>
            </ModalWindowTemplate>
            <LoadSpinnerAllPage show={signIn.loading}/>
        </React.Fragment>
    )
}

export default StepSignInForm