import React, {useEffect} from "react";

const RubicWidget = () => {

    useEffect(() => {

            let script2 = document.createElement('script');
            script2.type = 'text/javascript';
            var code =
                "    // describe widget configuration and saving to a global variable for future use\n" +
                "    var configuration = {\n" +
                "        from: 'BNB',\n" +
                "        to: '0x1E0b2992079B620AA13A7c2E7c88D2e1E18E46E9',\n" +
                "        fromChain: 'BSC',\n" +
                "        toChain: 'ETH',\n" +
                "        amount: .3,\n" +
                "        iframe: 'flex',\n" +
                "        hideSelectionFrom: false,\n" +
                "        hideSelectionTo: true,\n" +
                "        theme: 'dark',\n" +
                "        background: '#28372e',\n" +
                "        injectTokens: {\n" +
                "            'eth': [\n" +
                "                '0x1E0b2992079B620AA13A7c2E7c88D2e1E18E46E9'\n" +
                "            ]\n" +
                "        },\n" +
                "        slippagePercent: {\n" +
                "            instantTrades: 13,\n" +
                "            crossChain: 13\n" +
                "        },\n" +
                "        fee: 0.075,\n" +
                "        feeTarget: '0xF890Bb3B9219Aa2a5c1b247651Abf057ACCA5b12',\n" +
                "        promoCode: 'zCHS96Os'\n" +
                "    }\n" +
                "\n" +
                "    // prevent accidental changes to the object, for example, when re-creating a widget for another theme\n" +
                "    Object.freeze(configuration);\n" +
                "\n" +
                "    // create widget\n" +
                "    rubicWidget.init(configuration);\n"

            script2.appendChild(document.createTextNode(code));
            document.body.appendChild(script2);

    }, [])

    return (
        <div>
            <div id="rubic-widget-root"></div>
        </div>

    )
}

export default RubicWidget