import React, {useState, useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {userChangeNickname, userCheckNickname, setData} from "../../../../../Store/AuthV2/AuthV2Slice";

import Input from "../../../../../components/atoms/InputV2";
import Button from "../../../../../components/atoms/ButtonV3";
import LoadSpinner from "../../../../../components/atoms/LoadSpinner";
import LoadSpinnerAllPage from "../../../../../components/atoms/LoadSpinner/LoadSpinnerAllPage";
import ModalWindowTemplate from "../../../../../components/molecules/ModalWindowTemplate";

const StepNickname = ({show, stepData, changeStep}) => {
    const [nicknameValue, setNicknameValue] = useState("")
    const {checkNickname} = useSelector(state => state.authV2)
    const {userData} = useSelector(state => state.authV2.signIn)
    const {changeNickname} = useSelector(state => state.authV2)
    const dispatch = useDispatch()
    let timeout;

    useEffect(() => {
        if (changeNickname.success) {
            changeStep("")
        }
    }, [changeNickname.success])


    const debounce = useCallback((callback, nickname, delay) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            dispatch(callback({nickname, accessToken: userData.accessToken}))
        }, delay);
    }, [])

    const signOut = () => {
        dispatch(setData({path1: "signIn", path2: "userData", data: null}))
        changeStep("")
    }

    return (
        <React.Fragment>
            <ModalWindowTemplate
                show={show}
                isShowClose={false}
                className="auth-step_step14"
                title={<span>Create Your <br/> KOMPETE Nickname</span>}
            >
                <p>This is your in-game name that everyone will see. Nicknames can be changed later in your profile.</p>
                <div className="auth-step_step14_loadSpinner-block">
                    {
                        checkNickname.loading &&
                        <LoadSpinner/>
                    }
                </div>
                <Input
                    status={checkNickname.isValidNickname !== null && !checkNickname.isValidNickname ? "error" : "default"}
                    type="text"
                    label="nickname"
                    name="nickname"
                    onChange={(e) => (setNicknameValue(e.target.value) , debounce(userCheckNickname, e.target.value, 1000))}
                    value={nicknameValue}
                    error={!checkNickname.isValidNickname ? checkNickname.errorMessage : ""}
                    placeholder="Nickname"
                />
                <div className="auth-step_step14_nickname-rules">
                    <ul>
                        <li>Must be between 3 and 16 characters</li>
                        <li>May contain letters, numbers, non-consecutive dashes, periods, underscores, and spaces.</li>
                    </ul>
                </div>
                <Button
                    view="secondary"
                    onClick={() => dispatch(userChangeNickname({nickname: nicknameValue, accessToken: userData.accessToken}))}
                    disabled={!checkNickname.isValidNickname}
                >
                    Register nickname
                </Button>
                <Button view="opacity" onClick={() => signOut()}>Log out</Button>
            </ModalWindowTemplate>
            <LoadSpinnerAllPage show={changeNickname.loading}/>
        </React.Fragment>
    )
}

export default StepNickname