import Logo from "./logo.svg"
import Telegram from "./telegram.svg"
import Facebook from "./facebook.svg"
import Youtube from "./youtube.svg"
import Twitter from "./twitter.svg"
import Tictok from "./tictok.svg"
import MIcon_2 from "./why-icon4.svg"
import MIcon_9 from "./micon_9.svg"
import MIcon_10 from "./m-icon10.svg"
import ComingBrand from "./coming-brand.svg"
import FooterLogo from "./footer-logo.svg"
import Coingecko from "./coingecko-2.svg"
import CoinMarket from "./coinmarketcap-dark.svg"
import T9 from "./t9.svg"
import T10 from "./t10.svg"
import T11 from "./t11.svg"
import ProfileIcon from "./profile-icon.svg"
import Metamask from "./metamask.svg"
import LogoMobile from "./logo.svg"
import Coinbase from "./coinbase.svg"
import WalletConnect from "./walletconnect.svg"
import Formatic from "./fortmatic.svg"
import EtheriumGold from "./ethereum-gold.svg"
import Close from "./icons8-close.svg"
import Minus from "./minus.svg"
import MenuFilter from "./Group 533.svg"
import Left from "./leftSybol.svg"
import PLus from "./plus.svg"
import MenuFilterGold from "./Group 533-gold.svg"
import Activity from "./activity.svg"
import LeftPage from "./leftPage.svg"
import LeftAllPage from "./leftAllPage.svg"
import RightPage from "./rightPage.svg"
import RightAllPage from "./rightAllPage.svg"
import SelectDown from "./select-down.svg"
import UnCheck from "./select-uncheck.svg"
import Check from "./select-check.svg"
import Refresh from "./refresh.svg"
import Search from "./search.svg"
import SearchBlack from "./searchBlack.svg"
import Group1White from "./group1White.svg"
import Group1Gold from "./group1Gold.svg"
import Group2White from "./group2White.svg"
import Group2Gold from "./group2Gold.svg"
import Share2 from "./shareGold.svg"
import Profile from "./profile.svg"
import AccordionDown from "./accordion-down.svg"
import PriceHistory from "./price-history.svg"
import Details from "./details.svg"
import Offers from "./offers.svg"
import More from "./more.svg"
import Discord from "./discord.svg"
import TwitterBlue from "./twitter-blue.svg"
import SearchHelp from "./searchHelp.svg"
import Delete from "./delete.svg"
import Listed from "./listed.svg"
import Avatar from "./avatar.svg"
import AvatarCheck from "./avatar-check.svg"
import Edit from "./edit.svg"
import EditAvatar from "./edit-avatar.svg"
import CloseSmall from "./close - small.svg"
import PasswordClose from "./password-close.svg"
import PasswordOpen from "./password-open.svg"
import Success from "./success.svg"
import MetamaskFront from "./metamask-front.svg"
import Email from "./email.svg"
import RejectMessage from "./reject-not.svg"
import SuccessMessage from "./success-not.svg"
import Generate from "./generate.svg"
import Support from "./support.svg"
import SupportLive from "./support-live.svg"
import List from "./list.svg"
import Yes from "./yes.svg"
import No from "./no.svg"
import Date from "./date.svg"
import FacebookR from "./facebookRound.svg"
import TwitterR from "./twitterRound.svg"
import YoutubeR from "./youtubeRound.svg"
import Eth from "./eth.svg"
import Require from "./require.svg"
import BNB from "./bnb.svg"
import Fiat from "./fiat.svg"
import DiscordWhite from "./discord-white.svg"
import Market from "./market.svg"
import KNoBack from "./KNoBack.svg"
import Google from "./google.svg"
import Apple from "./apple.svg"
import Xbox from "./xbox.svg"
import TrustWallet from "./trustWallet.svg"
import ConnectWalletWhite from "./connectWallet-white.svg"
import Reject from "./reject.svg"
import Secured from "./secured.svg"
import Currency from "./currency.svg"
import TelegramGold from "./telegram-gold.svg"
import TwitterRed from "./twitter-red.svg"
import WhyIcon1 from "./why-icon1.svg"
import WhyIcon2 from "./why-icon2.svg"
import WhyIcon3 from "./why-icon3.svg"
import WhyIcon4 from "./why-icon4.svg"
import WhyIcon5 from "./why-icon5.svg"
import WhyIcon6 from "./why-icon6.svg"
import WhyIcon7 from "./why-icon7.svg"
import WhyIcon8 from "./why-icon8.svg"
import WhyIcon9 from "./why-icon9.svg"
import WhyIcon10 from "./why-icon10.svg"
import WhyIcon11 from "./why-icon11.svg"
import WhyIcon12 from "./why-icon12.svg"

import {ReactComponent as DiscordBlack} from "./discord-black.svg";
import {ReactComponent as TelegramBlack} from "./telegram-black.svg";
import {ReactComponent as Window} from "./window.svg";

export const svg = {
    Logo,
    Telegram,
    Facebook,
    Youtube,
    Twitter,
    Tictok,
    WhyIcon2,
    MIcon_2,
    MIcon_9,
    MIcon_10,
    ComingBrand,
    FooterLogo,
    Coingecko,
    CoinMarket,
    T9,
    T10,
    T11,
    ProfileIcon,
    Metamask,
    LogoMobile,
    Coinbase,
    WalletConnect,
    Formatic,
    EtheriumGold,
    Close,
    Minus,
    MenuFilter,
    Left,
    PLus,
    MenuFilterGold,
    Activity,
    LeftPage,
    LeftAllPage,
    RightPage,
    RightAllPage,
    SelectDown,
    UnCheck,
    Check,
    Refresh,
    Search,
    SearchBlack,
    Group1White,
    Group1Gold,
    Group2White,
    Group2Gold,
    Share2,
    Profile,
    AccordionDown,
    PriceHistory,
    Details,
    Offers,
    More,
    Discord,
    TwitterBlue,
    SearchHelp,
    Delete,
    Listed,
    Avatar,
    AvatarCheck,
    Edit,
    EditAvatar,
    CloseSmall,
    PasswordClose,
    PasswordOpen,
    Success,
    MetamaskFront,
    Email,
    RejectMessage,
    SuccessMessage,
    Generate,
    Support,
    SupportLive,
    List,
    No,
    Yes,
    Date,
    FacebookR,
    TwitterR,
    YoutubeR,
    Eth,
    Require,
    BNB,
    Fiat,
    DiscordWhite,
    Market,
    KNoBack,
    Google,
    Apple,
    Xbox,
    TrustWallet,
    ConnectWalletWhite,
    Reject,
    Secured,
    Currency,
    TelegramGold,
    TwitterRed,
    WhyIcon1,
    WhyIcon3,
    WhyIcon4,
    WhyIcon5,
    WhyIcon6,
    WhyIcon7,
    WhyIcon8,
    WhyIcon9,
    WhyIcon10,
    WhyIcon11,
    WhyIcon12
}

export const SVG = {
    discordBlack: DiscordBlack,
    telegramBlack: TelegramBlack,
    window: Window
}