import React, {useEffect, useState} from "react";
import classNames from "classnames";
import "./style.scss"

const DropdownItem = ({title, text, children, open = false}) => {
    const [isOpen, setIsOpen] = useState(open)

    useEffect(() => {
        setIsOpen(open)
    }, [open])

    const dropDownContainer = classNames("dropdownItem", {active: isOpen})
    const dropDownTitle = classNames("dropdownItem_title", {active: isOpen})
    const dropdownContent = classNames("dropdownItem_content", {active: isOpen})

    return (
        <div className={dropDownContainer}>
            <div className="content">
                <div className={dropDownTitle}>
                    <h3>{title}</h3>
                </div>
                <div className={dropdownContent}>
                    {
                        text ? <p>{text}</p> : children
                    }
                </div>
            </div>
            <div className="more" onClick={() => setIsOpen(!isOpen)}>
                {
                    isOpen ? <span>-</span> : <span>+</span>
                }
            </div>
        </div>
    )
}

export default DropdownItem