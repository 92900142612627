import React from "react";
import "./style.scss"

const Chart = () => {
    return (
        <div id="dexscreener-embed">
            <iframe
                loading="lazy"
                src="https://dexscreener.com/ethereum/0x07840B4825b74cC6ce264bF2743DEe647194f49b?embed=1&theme=dark&trades=0&info=0">.
            </iframe>
        </div>
    )
}

export default Chart