import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {validateEmail} from "../../../../../Utils/utils";
import {reset} from "../../../../../Store/AuthV2/AuthV2Slice"

import Input from "../../../../../components/atoms/InputV2";
import CheckBox from "../../../../../components/atoms/CheckBox";
import Button from "../../../../../components/atoms/ButtonV3";
import ModalWindowTemplate from "../../../../../components/molecules/ModalWindowTemplate";
import ChangeStepButtonTemplate from "../ReusableComponents/ChangeStepButtonTemplate";

import "../style.scss"

const StepSignUpForm = ({show, changeStep, fieldHandle, stepData, checkFields}) => {
    const {email, password, checkbox1, checkbox2, checkbox3} = stepData.steps.step5.fields
    const {signUp} = useSelector(state => state.authV2)
    const {signUpSocial} = useSelector(state => state.authV2)
    const {fields} = stepData.steps.step8
    const isStep5 = stepData.currentStep === "step5"
    const [error, setError] = useState("")
    const dispatch = useDispatch()

    useEffect(() => {
        if (signUp.error || signUpSocial.error) {
            setError(signUp.errorMessage)
            dispatch(reset("signUp"))
        }
    }, [signUp.error, signUpSocial.error])

    const register = () => {
        const passwordRegexp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/g

        if (isStep5) {
            checkFields()
            if (!email.value || !password.value) {
                setError("Account details incorrect. Please enter again")
                return;
            } else {
                if (!validateEmail(email.value)) {
                    setError("Invalid email address")
                    checkFields("email")
                    return;
                }

                if (!password.value.match(passwordRegexp)) {
                    setError("Invalid password")
                    checkFields("password")
                    return;
                }
            }

            if (!checkbox1.value) {
                setError("You must agree to the terms of service")
                return;
            }

            if (!checkbox3.value) {
                setError("You should be 13 and above ")
                return;
            }

        } else {
            if (!fields.checkbox1.value) {
                setError("You must agree to the terms of service")
                return;
            }

            if (!fields.checkbox3.value) {
                setError("You should be 13 years and above ")
                return;
            }
        }

        changeStep("step6")
    }

    return (
        <ModalWindowTemplate
            show={show}
            privacy={true}
            paddingTop={true}
            powered={true}
            className={"auth-step_" + stepData.currentStep}
            changeStep={changeStep}
            title={<span>Create your  <br/> KOMPETE Account</span>}
            isShowClose={isStep5}
        >
            {
                isStep5 &&
                <React.Fragment>
                    <Input
                        status={email.validation || error ? "error" : "default"}
                        type="email"
                        label="email address"
                        name="email"
                        onChange={fieldHandle}
                        value={email.value}
                        error={error}
                        placeholder="Email address"
                    />
                    <Input
                        status={password.validation || error ? "error" : "default"}
                        type="password"
                        label="password"
                        name="password"
                        onChange={fieldHandle}
                        value={password.value}
                        placeholder="Password"
                    />
                    <span className="auth-step_step5_inp-info">
                        Must have 7+ characters, at least 1 number, at least 1 letter, and no whitespace.
                    </span>
                </React.Fragment>
            }
            <div className="checkbox-block">
                <CheckBox
                    type={"checkBox-square square-medium square-green"}
                    name={"checkbox1"}
                    onChange={fieldHandle}
                    checked={isStep5 ? checkbox1.value : fields.checkbox1.value}
                >
                    I have read and agree to the <Link to="/terms">KOMPETE Terms of Service</Link>
                </CheckBox>
                <CheckBox
                    type={"checkBox-square square-medium square-green"}
                    name={"checkbox3"}
                    onChange={fieldHandle}
                    checked={isStep5 ? checkbox3.value : fields.checkbox3.value}
                >
                    I am over 13 years old
                </CheckBox>
            </div>
            <Button view="secondary" onClick={() => register()}>Sign up</Button>
            {
                isStep5 &&
                <ChangeStepButtonTemplate changeStep={changeStep} type="signIn" textLink={true}/>
            }
        </ModalWindowTemplate>
    )
}

export default StepSignUpForm