import React, {useState, useEffect} from "react";
import {useWeb3React} from "@web3-react/core";
import {ConnectorEvent} from "@web3-react/types";
import {useDispatch, useSelector} from "react-redux";

import {web3Utils} from "../../../../web3/utils";
import {setData} from "../../../../Store/AuthV2/AuthV2Slice";
import {getCurrentDivineNFT} from "../../../../Store/DivineShop/divineShopSlice";

import CosmeticViewTemplate from "../../../molecules/CosmeticViewTemplate";
import CountDownWithContainer from "../../../molecules/Countdown/CountDownWithContainer/CountDownWithContainer";
import QuantityCalculator from "../../../atoms/QuantityCalculator";
import Rarity from "../../../atoms/Rarity";
import Button from "../../../atoms/ButtonV3";
import Image from "../../../atoms/Image";
import DivineSteps from "./DivineSteps";
import SkeletonText from "../../../atoms/SkeletonText";

import {svg} from "../../../../assets/svg";
import {png} from "../../../../assets/png";
import "./style.scss"

const DivineShop = ({changeStep, isShowCosmetic, isMint}) => {
    const {deactivate, active, connector, account} = useWeb3React()
    const {signIn} = useSelector(state => state.authV2)
    const {userDataXsolla} = useSelector(state => state.authV2)
    const {divine} = useSelector(state => state.divineShop)
    const [quantityValue, setQuantityValue] = useState(1)
    const [whichModalOpen, setWhichModalOpen] = useState("")
    const dispatch = useDispatch()
    const authToken = window.sessionStorage.accessToken

    useEffect(() => {
        if (signIn.success && typeof signIn.userData?.ethBalance === "number" || typeof signIn.userData?.ethBalance === "string") {
            const walletUpdate = (data) => {
                web3Utils.getBalanceETH(data.account, connector)
                    .then((balance) => {
                        const newData = {...signIn.userData, ethBalance: balance}
                        dispatch(setData({path1: "signIn", path2: "userData", data: newData}))
                    })
                deactivate()
                setWhichModalOpen("")
            }
            const walletUpdate1 = () => {
                const newData = {...signIn.userData, ethBalance: 0}

                dispatch(setData({path1: "signIn", path2: "userData", data: newData}))
                setWhichModalOpen("")
                deactivate()
            }

            if (active && connector) {
                connector.on(ConnectorEvent.Update, walletUpdate)
                connector.on(ConnectorEvent.Deactivate, walletUpdate1)
            }

            return () => {
                if (connector) {
                    connector.off(ConnectorEvent.Update, walletUpdate)
                    connector.off(ConnectorEvent.Deactivate, walletUpdate1)
                }
            }
        }
    }, [connector, signIn.userData])

    useEffect(() => {
        if (isShowCosmetic && !divine.data?.is_show_in_store) dispatch(getCurrentDivineNFT())
    }, [dispatch])

    const buyNow = () => {
        if (active && account) {
            web3Utils.getBalanceETH(account, connector)
                .then((result) => {
                    const newData = {...signIn.userData, ethBalance: result}
                    dispatch(setData({path1: "signIn", path2: "userData", data: newData}))
                })
                .then(() => setWhichModalOpen("order"))
        } else {
            setWhichModalOpen("order")
        }
    }

    return (
        <section className="shop-divine container">
            <CosmeticViewTemplate
                classname="shop-divine_content"
                title={isShowCosmetic ? divine?.data?.name : <span className="interrogative-color">???????????</span>}
                img={isShowCosmetic ? divine?.data?.image_url : png.Interrogative}
                loading={divine.loading}
            >
                <div className="shop-divine_content_rarity">
                    <span>Outfit</span>
                    <Rarity
                        text="divine"
                        rarity="divine"
                        size="lg"
                    />
                </div>
                <div className="shop-divine_content_deadline">
                    <CountDownWithContainer
                        text="This item will never be sold again!"
                        date={divine?.data?.expiration_date}
                        day={false}
                        loading={divine.loading}
                        isSoon={!isMint}
                    />
                </div>
                <div className="shop-divine_content_price-info">
                    <div className="shop-divine_content_price-info_price">
                        <span className="shop-divine_content_price-info_price_title">Price for {quantityValue}</span>
                        <div className="shop-divine_content_price-info_price_content">
                            <Image src={svg.EtheriumGold} alt="Ethereum"/>
                            <span className={divine.loading ? "skeleton" : ""}>
                                {isShowCosmetic
                                    ? divine?.data?.price && quantityValue * divine?.data?.price
                                    : <span className="interrogative-color">????</span>}
                            </span>
                        </div>
                    </div>
                    {isMint &&
                    <div className="shop-divine_content_price-info_quantity">
                        <span className="shop-divine_content_price-info_quantity_title">Quantity</span>
                        <QuantityCalculator value={quantityValue} setValue={setQuantityValue} limit={5}/>
                    </div>
                    }
                </div>
                {isMint &&
                <React.Fragment>
                    {authToken && signIn.success ?
                        <Button
                            view="secondary"
                            onClick={buyNow}
                        >
                            Buy Now
                        </Button>
                        :
                        <Button
                            disabled={userDataXsolla.loading} v
                            view="secondary"
                            onClick={() => changeStep("step1")}
                        >
                            Sign in
                        </Button>}
                </React.Fragment>}
                <div className="shop-divine_content_description">
                    <span className="shop-divine_content_description_title">Description</span>
                    {isShowCosmetic ?
                        divine.loading
                            ? <SkeletonText textLineCount={5}/>
                            : <p>{divine?.data?.description}</p>
                        : <p>Look good. Feel Good. Play Good. You’re a founding father and everyone will know it when you put on this
                            [???]. The first cosmetic item ever sold in KOMPETE. Equip this [???] as an outfit on your KOMPETE
                            avatar.</p>}
                </div>
            </CosmeticViewTemplate>
            <DivineSteps
                quantity={quantityValue}
                setWhichModalOpen={setWhichModalOpen}
                whichModalOpen={whichModalOpen}
            />
        </section>
    )
}
export default DivineShop