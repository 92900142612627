import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    backgroundImgClass: ""
}

const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        setBackgroundClass: (state, action) => {
            state.backgroundImgClass = action.payload
        },
    }
})

export const {setBackgroundClass} = globalSlice.actions
export default globalSlice.reducer