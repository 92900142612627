import React, {useEffect, useState, useRef} from "react";
import {useNavigate} from "react-router-dom";
import useWindowSize from "../../../../Hooks/useWindowSize";
import {useLocation} from "react-router-dom";

import Button from "../../../atoms/ButtonV3";
import RubicWidget from "../../../molecules/RubicWidget";
import Toast from "../../../atoms/Toast";
import Image from "../../../atoms/Image";
import UnderLine from "../../../atoms/UnderLine";

import {png} from "../../../../assets/png";
import {gif} from "../../../../assets/gif";
import "./style.scss"

const HowToBuyTokenTwo = () => {
    const {pathname, state} = useLocation()
    const [errorOptions, setErrorOptions] = useState({text: "", variant: ""})
    const BNB = pathname === "/bnb"
    const chain = BNB ? "BNB" : "ETH"
    const sectionRef = useRef(null)
    const [pageVisible, setPageVisible] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        const elemTop = sectionRef.current.getBoundingClientRect().top
        if (elemTop && pageVisible) {
            window.scrollTo(0, elemTop - 100)
            navigate("/buy-kompete-token", {state: {}})
        }
    }, [pageVisible])

    useEffect(() => {
        if (state?.process === "order-more-eth") {
            setPageVisible(true)
        }
    }, [state?.process])


    const integrateToken = async () => {
        const tokenAddress = '0x1E0b2992079B620AA13A7c2E7c88D2e1E18E46E9';
        const tokenSymbol = 'KOMPETE';
        const tokenDecimals = 10;
        const tokenImage = 'https://kompete.game/images/Sites_KOMPETEToken.png';

        try {
            const wasAdded = await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: tokenAddress,
                        symbol: tokenSymbol,
                        decimals: tokenDecimals,
                        image: tokenImage,
                    },
                },
            });

            if (wasAdded) {
                setErrorOptions({text: "Success", variant: "success"})
            }
        } catch (error) {
            setErrorOptions({text: "Reject", variant: "error"})
        }
    }

    return (
        <React.Fragment>
            <section className="how-to-buy-token_two">
                <div className="container">
                    <div className="how-to-buy-token_two_step-one">
                        <div className="how-to-buy-token_two_step-one_block-one">
                            <Image alt="eth" src={png.GoldEtherum}/>
                        </div>
                        <div className="how-to-buy-token_two_step-one_block-two">
                            <h2 className=" how-to-buy-token_two_step-one_block-two_title">
                                <span>Step 1</span><br/>
                                Create a web 3 wallet
                            </h2>
                            <UnderLine/>
                            <p className="how-to-buy-token_two_step-one_block-two_text">
                                First, you’ll need to create a MetaMask Web3 wallet. This is what will ‘hold’ your
                                KOMPETE Tokens. Don’t forget to store your seed phrase somewhere safe!
                            </p>
                            <div className="how-to-buy-token_two_step-one_block-two_btn">
                                <Button
                                    view="secondary"
                                    onClick={() => window.open("https://metamask.io/", "_target")}
                                >
                                    metamask
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div ref={sectionRef} className="how-to-buy-token_two_step-two" id="test">
                        <div className="how-to-buy-token_two_step-two_block-one">
                            <h2 className=" how-to-buy-token_two_step-two_block-one_title">
                                <span>Step 2</span><br/>
                                Fund wallet with {chain}
                            </h2>
                            <UnderLine/>
                            <p className="how-to-buy-token_two_step-two_block-one_text">
                                You’ll need to buy some {BNB ? chain : "Ethereum"} so that you can swap it for KOMPETE
                                Token. Use our Onramper
                                tool to purchase, and send it to your newly created wallet address.
                            </p>
                        </div>
                        <div className="how-to-buy-token_two_step-two_block-two">
                            <Iframe
                                src={`https://widget.onramper.com?color=266677&apiKey=pk_prod_VAaUZ80TevjccpS0t1huZ7HaHpXRyO2Vjt8xW1AO2zA0&defaultCrypto=${BNB ? "BNB" : "ETH"}`}/>
                        </div>
                    </div>
                    <div className="how-to-buy-token_two_step-three">
                        <div className="how-to-buy-token_two_step-three_block-one">
                            {
                                BNB ?
                                    <RubicWidget/>
                                    :
                                    <Iframe
                                        src={"https://app.uniswap.org/#/swap?outputCurrency=0x1E0b2992079B620AA13A7c2E7c88D2e1E18E46E9&use=v2&slippage=12000&chain=mainnet"}/>
                            }
                        </div>
                        <div className="how-to-buy-token_two_step-three_block-two">
                            <h2 className=" how-to-buy-token_two_step-three_block-two_title">
                                <span>Step 3</span><br/>
                                Swap {chain} for kompete token
                            </h2>
                            <UnderLine/>
                            <p className="how-to-buy-token_two_step-three_block-two_text">
                                Select the amount of {BNB ? chain : "Ethereum"} you want to swap for KOMPETE Token, and
                                then
                                set slippage to
                                3% by clicking the gear icon. Connect your wallet and follow the prompts to execute the
                                swap!
                            </p>
                            {
                                pathname !== "/bnb" &&
                                <div className="how-to-buy-token_two_step-three_block-two_btn">
                                    <Button
                                        view="secondary"
                                        onClick={() => window.open("https://app.uniswap.org/#/swap?outputCurrency=0x1E0b2992079B620AA13A7c2E7c88D2e1E18E46E9&use=v2&slippage=12000&chain=mainnet")}
                                    >
                                        uniswap
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="how-to-buy-token_two_step-four">
                        <div className="how-to-buy-token_two_step-four_block-one">
                            <h2 className=" how-to-buy-token_two_step-four_block-one_title">
                                <span>Step 4</span><br/>
                                Add tokens to wallet
                            </h2>
                            <UnderLine/>
                            <p className="how-to-buy-token_two_step-four_block-one_text">
                                Lastly, be sure to add the tokens to your Web3 wallet so you can see them! You can do
                                this
                                by importing KOMPETE token to your wallet by clicking the button below or importing this
                                address manually: <span>0x1E0b2992079B620AA13A7c2E7c88D2e1E18E46E9</span>
                            </p>
                            <div className="how-to-buy-token_two_step-four_block-one_btn">
                                <Button
                                    view="secondary"
                                    onClick={() => integrateToken()}
                                >
                                    Add token To Wallet
                                </Button>
                            </div>
                        </div>
                        <div className="how-to-buy-token_two_step-four_block-two">
                            <Image alt="kompete-gif" src={gif.KompeteToken}/>
                        </div>
                    </div>
                </div>
            </section>
            <Toast text={errorOptions.text} clearState={setErrorOptions} variant={errorOptions.variant}/>
        </React.Fragment>
    )
}

export default HowToBuyTokenTwo

const Iframe = ({src}) => {
    const [iframeStyle, setIframeStyle] = useState({width: "", height: ""})
    const {width} = useWindowSize()

    useEffect(() => {
        if (width > 1200) setIframeStyle({width: "520", height: "620"})
        if (width > 991 && width < 1200) setIframeStyle({width: "430", height: "490"})
        if (width < 991) setIframeStyle({width: "334", height: "391"})
    }, [width])


    return (
        <div id="rubics-widget-root" align="center">
            <iframe
                src={src}
                title="Onramper widget"
                frameBorder="0"
                allow="accelerometer;autoplay; camera; gyroscope; payment"
                className="how-to-buy-token_two_step-three_block-one_iframe"
                width={iframeStyle.width}
                height={iframeStyle.height}
                style={{boxShadow: "1px 1px 1px 1px"}}
            >
                <a href="https://widget.onramper.com" target="_blank" rel="noreferrer">Buy crypto</a>
            </iframe>
        </div>
    )
}