import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getFirstThreePosts} from "../../../../Store/NewsPosts/postSlice";

import ArticleCart from "../../../atoms/ArticleCart";
import Title from "../../../atoms/TitleTemplate";
import "./style.scss"

const PostNews = () => {
    const {firstThreePosts} = useSelector(state => state.posts)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!firstThreePosts) dispatch(getFirstThreePosts(3))
    }, [])

    return (
        <section className="current-news-container_news">
            <Title underline={true}>Latest <span>Posts</span></Title>
            <div className="container">
                {
                    firstThreePosts?.map((post, i) =>
                        <ArticleCart linkTo={`/news/${post.slug}`} key={i} text={post.title} date={post.date}
                                     img={post.image.url}/>
                    )
                }
            </div>
        </section>
    )
}

export default PostNews