import React, {useEffect} from "react"
import {useDispatch} from "react-redux";
import {getFirstNinePosts} from "../../../Store/NewsPosts/postSlice";

import Post from "../../../components/organisms/News/Posts/Post";
import DefaultLayout from "../../../layouts/DefaultLayout";
import PostNews from "../../../components/organisms/News/Posts/PostsNews";
import Seo from "../../../components/atoms/Seo";

const NewsPost = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getFirstNinePosts(9))
    }, [])

    return (
        <DefaultLayout>
            <Seo
                title="News - KOMPETE"
                description="Catch up on the latest updates for KOMPETE."
            />
            <Post/>
            <PostNews/>
        </DefaultLayout>
    )

}
export default NewsPost