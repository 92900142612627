import React from "react";
import Title from "../../atoms/TitleTemplate";
import ReactPlayer from "../../atoms/ReactPlayer";

import Image from "../../atoms/Image";

import {png} from "../../../assets/png";
import "./style.scss"

const EcoModular = () => {
    return (
        <section className="economy_modular container">
            <Title underline={true}>Modular <span>character <br/> customization</span></Title>
            <div className="economy_modular_content">
                <div className="row-right-img">
                    <div>
                        <h2>Look Good. Feel good.<br/> Play Good.</h2>
                        <p>When you load up KOMPETE, you'll get an avatar to customize modularly to your liking. You can easily edit
                            your avatar's hair, hair color, top, bottom, shoes, game equipment, and more!</p>
                        <p>This modular system offers endless combinations and keeps you looking unique on Mahzookoo Island. As we
                            grow, we'll continue increasing the depth of the modular character system.</p>
                    </div>
                    <div className="video-block">
                        <ReactPlayer
                            src="https://player.vimeo.com/video/808879542?h=9f5b29647a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;muted=1&amp;autoplay=1&amp;loop=1&amp;controls=0"
                            title="VSL_Customization_4K.mp4"/>
                    </div>
                </div>
                <div className="row-left-img">
                    <div>
                        <Image src={png.Marketplace} alt="marketplace"/>
                    </div>
                    <div>
                        <h2>Tons of unique cosmetics to use</h2>
                        <p>We have a boatload of cosmetics to equip and express yourself with! You can gear up with weapons, karts,
                            apparel, accessories, stickers, emotes, and more. </p>
                    </div>
                </div>
                <div className="row-right">
                    <div>
                        <h2>Six different cosmetic rarities!</h2>
                        <p>Not all cosmetics are cut from the same cloth! KOMPETE has six different cosmetic rarities: Ordinary,
                            Common,
                            Rare, Elite, Legendary, and Divine.</p>
                    </div>
                    <div>
                        <Image src={png.RarityGroup} alt="marketplace"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EcoModular