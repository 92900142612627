import React from "react"
import {useNavigate} from "react-router-dom";

import Button from "../../atoms/ButtonV3";
import Title from "../../atoms/TitleTemplate";
import Image from "../../atoms/Image";

import {svg} from "../../../assets/svg";
import "./style.scss"

const TokenFour = () => {
    const navigate = useNavigate()

    return (
        <section className="token-four">
            <div className="container">
                <Title underline={true}>How to <span>buy</span></Title>
                <div className="steps">
                    <div className="step-item">
                        <div className="icon">
                            <Image alt="eth" src={svg.T10}/>
                        </div>
                        <p>Ethereum</p>
                        <Button view="secondary" onClick={() => navigate("/buy-kompete-token")}>Buy with eth</Button>
                    </div>
                    <div className="step-item">
                        <div className="icon">
                            <Image alt="bnb" src={svg.BNB}/>
                        </div>
                        <p>Bnb</p>
                        <Button view="secondary" onClick={() => navigate("/bnb")}>Buy with bnb</Button>
                    </div>
                    <div className="step-item">
                        <div className="icon">
                            <Image alt="fiat" src={svg.Fiat}/>
                        </div>
                        <p>Fiat</p>
                        <Button view="secondary" onClick={() => navigate("/buy-kompete-token")}>Buy with fiat</Button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TokenFour