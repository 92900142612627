import React from "react";
const TokenTwo = () => {
    return (
        <section className="token-two">
            <div className="token-two_content">
                <div className="token-two_content_title">
                    <h1>Bridging The GAP BETWEEN Web2 and <span>Web3 Gaming</span></h1>
                </div>
                <div className="token-two_content_img"></div>
            </div>
        </section>
    )
}

export default TokenTwo