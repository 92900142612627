import React, {useEffect} from "react";
import {useSelector} from "react-redux";

import LoadSpinnerAllPage from "../../../../../components/atoms/LoadSpinner/LoadSpinnerAllPage";
import ModalWindowTemplate from "../../../../../components/molecules/ModalWindowTemplate";
import Button from "../../../../../components/atoms/ButtonV3";

import form from "./form";

import "../style.scss"

const StepIsConnectWallet = ({show, changeStep, registerUserDispatch, setStepData}) => {
    const {success, error, loading} = useSelector(state => state.authV2.signUp)
    const {signUpSocial} = useSelector(state => state.authV2)
    const formCopy = JSON.parse(JSON.stringify(form))
    const {registration} = useSelector(state => state.authV2.userVerification)
    const registrationType = registration ? "social" : "regular"

    useEffect(() => {
        if (success || signUpSocial.success) setStepData({...formCopy, currentStep: "step7"})
        if (error) changeStep("step5")
        if (signUpSocial.error) changeStep("step8")
    }, [error, success, signUpSocial.error, signUpSocial.success])

    return (
        <React.Fragment>
            <ModalWindowTemplate
                show={show}
                powered={true}
                privacy={true}
                className="auth-step_step6"
                isShowClose={false}
                changeStep={changeStep}
                title={<span>Want to connect a wallet to your KOMPETE Account?</span>}
            >
                <p className="auth-step_step6_text">You don’t need this to play and you can connect it later in your profile.</p>
                <div className="auth-step_step6_buttons">
                    <Button view="secondary" onClick={() => changeStep("step9")}>Yes</Button>
                    <Button
                        view="opacity"
                        onClick={() => registerUserDispatch(null, registrationType)}
                    >
                        No
                    </Button>
                </div>
            </ModalWindowTemplate>
            <LoadSpinnerAllPage show={(loading || signUpSocial.loading)}/>
        </React.Fragment>
    )
}

export default StepIsConnectWallet