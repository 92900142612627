import React,{memo} from "react";
import {useState} from "react";

const Image = ({src, alt, onClick, className}) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    return <img
        className={className}
        onClick={onClick}
        loading="lazy"
        draggable={false}
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
        style={{ visibility:  imageLoaded ? "visible" : "hidden"}}
    />
}

export default memo(Image)