import React from "react";
import "./style.scss"

const LoadSpinnerTwo = () => {
    return (
        <div className="lds-default">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}
export default LoadSpinnerTwo