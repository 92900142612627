import React, {useEffect, useState} from "react"
import "./style.scss"
import {timeZoneDifference} from "../../../Utils/utils";

const CountDown = ({date, day = true}) => {
    const currentTime = timeZoneDifference(date)
    const [timeLeft, setTimeLeft] = useState({})

    let timeInterval

    useEffect(() => {
        calculateTimeLeft()
        timeInterval = setInterval(calculateTimeLeft, 1000)

        return () => {
            clearInterval(timeInterval)
        }
    }, [])


    function calculateTimeLeft() {
        let difference = new Date(currentTime) - new Date()
        let time = {}
        if (difference > 0) {
            time = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            }

            setTimeLeft(time)
        } else {
            time = {
                days: "0",
                hours: "0",
                minutes: "0",
                seconds: "0",
            }
            setTimeLeft(time)
            clearInterval(timeInterval)
        }
    }


    return (
        <div className="countdown">
            <div className="numbers">
                {
                    day &&
                    <React.Fragment>
                        <div className="num-card">
                            <span className="num">{timeLeft.days < 10 ? "0" + timeLeft.days : timeLeft.days}</span>
                            <span className="num-label">Days</span>
                        </div>
                        <span className="divider">:</span>
                    </React.Fragment>
                }
                <div className="num-card">
                    <span className="num">{timeLeft.hours < 10 ? "0" + timeLeft.hours : timeLeft.hours}</span>
                    <span className="num-label">Hours</span>
                </div>
                <span className="divider">:</span>
                <div className="num-card">
                    <span className="num">{timeLeft.minutes < 10 ? "0" + timeLeft.minutes : timeLeft.minutes}</span>
                    <span className="num-label">Minutes</span>
                </div>
                <span className="divider">:</span>
                <div className="num-card">
                    <span className="num"
                          id="secs">{timeLeft.seconds < 10 ? "0" + timeLeft.seconds : timeLeft.seconds}</span>
                    <span className="num-label">Seconds</span>
                </div>
            </div>
        </div>
    )
}

export default CountDown