import React from "react";
import {png} from "../../../assets/png";
import Title from "../../atoms/TitleTemplate";
import Image from "../../atoms/Image";
import "./style.scss"

const TokenThree = () => {
    return (
        <section className="token-three">
            <div className="container">
                <Title underline={true}>The <span>tokenomics</span></Title>
                <div className="tokenomics">
                    <div className="item">
                        <div className="thumb">
                            <Image alt="kompete-token" src={png.T12}/>
                        </div>
                        <h3>Token <span>Supply</span></h3>
                        <p>Only 850 million KOMPETE Token will ever be in circulation. There is a wallet limit set to 10 million
                            tokens.</p>
                    </div>
                    <div className="item">
                        <div className="thumb">
                            <Image alt="komoete-token" src={png.T13}/>
                        </div>
                        <h3>Token <span>TAX</span></h3>
                        <p>There’s an 3% development and marketing tax on all swaps. The tax will be lowered as we grow in the future!</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TokenThree