import React, {useEffect} from "react"
import {useSelector} from "react-redux";

import WalletsModal from "../../../../../components/molecules/WalletModal";

const StepNineWallet = ({show, changeStep,registerUserDispatch, connect}) => {
    const {success, error} = useSelector(state => state.authV2.signUp)
    const {registration} = useSelector(state => state.authV2.userVerification)
    const registrationType = registration ? "social" : "regular"

    useEffect(() => {
        if (error) changeStep("step5")
        if (success) changeStep("step7")
    }, [success, error])

    return (
        <WalletsModal
            show={show}
            closeClick={changeStep}
            buttonClick={() => registerUserDispatch(null, registrationType)}
            connect={connect}
        />
    )
}

export default StepNineWallet