import React from "react";
import classNames from "classnames";
import "./style.scss"
import Image from "../Image";
import Icon from "../Icon";

const Button = ({view, size, icon, iconDirection, path, disabled, onClick, children}) => {

    const buttonStyle = classNames("buttonV3", view ?? "primary", size ?? "lg", {path: path})
    const iconStyle = classNames("buttonV3_icon", iconDirection ?? "")

    return (
        <button onClick={onClick} disabled={disabled} className={buttonStyle}>
            {
                icon && <div className={iconStyle}><Icon name={icon}/></div>
            }
            <div>
                {children}
            </div>
        </button>
    )
}
export default Button