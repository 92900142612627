import React, {useRef, useState} from "react";
import {Link} from "react-router-dom"
import {useSelector} from "react-redux";
import {useWeb3React} from "@web3-react/core";
import classNames from "classnames";

import {formatBalance} from "../../../../Utils/utils";

import Button from "../../../../components/atoms/ButtonV3";
import UseOutSide from "../../../../Hooks/useOutside";
import useWindowSize from "../../../../Hooks/useWindowSize";

import {svg} from "../../../../assets/svg";
import {png} from "../../../../assets/png";
import "./../style.scss"
import Image from "../../../../components/atoms/Image";

const UserLoginDisplay = ({signOut, changeStep, SetMobileMenu}) => {
    const {userData} = useSelector(state => state.authV2.signIn)
    const [isOpenUserMenu, setIsOpenUserMenu] = useState(false)
    const wrapperRef = useRef(null)
    const {width} = useWindowSize()
    const {active, account} = useWeb3React()

    const noConnectedWallet = classNames("navigation_auth_user-details_panel_info_balance_eth", {"no-wallet": !active && !account})
    const noRegisteredWallet = classNames("navigation_auth_user-details_panel_info_balance_kompete", {"no-wallet": !userData?.wallet})

    UseOutSide(wrapperRef, setIsOpenUserMenu)

    return (
        <React.Fragment>
            {
                window.sessionStorage.accessToken
                    ?
                    <div className="navigation_auth_user-details">
                        <div className="navigation_auth_user-details_panel">
                            <div className="navigation_auth_user-details_panel_info">
                                <div className="navigation_auth_user-details_panel_info_balance">
                                    <div
                                        className={noConnectedWallet}>
                                        <div>
                                            <Image alt="eth" src={svg.Eth}/>
                                        </div>
                                        <span>{userData?.ethBalance ? formatBalance(userData?.ethBalance) : 0}</span>
                                    </div>
                                    <div
                                        className={noRegisteredWallet}>
                                        <div>
                                            <Image alt="kompete-token" src={png.T1}/>
                                        </div>
                                        <span>{userData?.kompeteBalance ? formatBalance(userData?.kompeteBalance) : 0}</span>
                                    </div>
                                </div>
                            </div>
                            <div ref={wrapperRef} className="navigation_auth_user-details_panel_options">
                                <button
                                    onClick={() => width > 991 ? setIsOpenUserMenu(!isOpenUserMenu) :
                                        SetMobileMenu({
                                            open: true, section: "userMenu"
                                        })}
                                >
                                    <div className="navigation_auth_user-details_panel_options_img">
                                        <Image alt="avatar"
                                             src={userData?.picture ? userData?.picture : png.NewAvatar}/>
                                        <span className="online"></span>
                                    </div>
                                    <div className="navigation_auth_user-details_panel_options_selectDown">
                                        <Image src={svg.SelectDown} alt="down"/>
                                    </div>
                                </button>
                                {
                                    isOpenUserMenu &&
                                    <div className="navigation_auth_user-details_panel_options_hidden-block">
                                        <div
                                            className="navigation_auth_user-details_panel_options_hidden-block_section">
                                            <Link to="/profile">Profile</Link>
                                            <button onClick={() => (signOut(), setIsOpenUserMenu(false))}>Logout</button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <div className="navigation_auth_signIn-btn">
                        <Button size="sm" view="white" onClick={() => changeStep("step1")}>Sign in</Button>
                    </div>
            }

        </React.Fragment>
    )
}

export default UserLoginDisplay