import React from "react";
import {Helmet} from "react-helmet-async"
import FaqAskQuestion from "../../components/organisms/FAQ/FaqAskQuestion";
import FaqMain from "../../components/organisms/FAQ/FaqMain";
import DefaultLayout from "../../layouts/DefaultLayout";

const FAQ = () => {

    return (
        <DefaultLayout partOfFooterShow={true}>
            <Helmet>
                <title>FAQ - KOMPETE</title>
                <meta name="description" content="Frequenty Asked Questions about KOMPETE and its Ecosystem."/>
            </Helmet>
            <FaqMain/>
            <FaqAskQuestion/>
        </DefaultLayout>
    )
}

export default FAQ