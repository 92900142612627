import React from "react";
import classNames from "classnames";

import ProgressiveImage from "../ProgressiveImage";
import UnderLine from "../UnderLine";

import "./style.scss"

const PageMain = ({page, originalImage, resizeImage, title, text, filter, children}) => {
    const mainPageStyle = classNames("main-page", page, {filter: filter})

    return (
        <section className={mainPageStyle}>
            {filter && <div className="main-page_filter"></div>}
            <ProgressiveImage
                imgSrc={originalImage}
                previewSrc={resizeImage}
                className="main-page_back-img"
            />
            <div className="main-page_title">
                <h1>{title}</h1>
                <UnderLine/>
                <p>{text}</p>
                {children &&
                    <div className="main-page_title_content">
                        {children}
                    </div>}
            </div>
        </section>
    )
}

export default PageMain