export const nftArrayFilter = (currentButton, maxNft, nftArray) => {
    return nftArray?.filter((_, index) => index > maxNft * currentButton - maxNft && index <= currentButton * maxNft)
}

export const copyToClipboard = (text) => {
    return navigator.clipboard.writeText(text).then(function () {
        return true
    }, function (err) {
        return false
    });
}

export const addressReduction = (address, number) => {
    let addressPartSecond = address.split("").splice(address.length - 4, address.length).join("")
    let addressPartFirst = address.split("").splice(0, number).join("")

    return addressPartFirst + "..." + addressPartSecond
}

export const validateEmail = (email) => {
    let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    return email.match(mailFormat)
}

export const randomToken = (maxLimit) => {
    let token = ""
    const tokenFormat = "QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm123456789"

    for (let i = 0; i < 60; i++) {
        let randomNumber = Math.floor(Math.random() * maxLimit)
        token += tokenFormat[randomNumber]
    }

    return token
}

export const formatBalance = (n) => {
    return (Math.round(n * 100) / 100).toLocaleString();
}

export const expirationTimeCalculator = (day) => {
    const currentDay = new Date()

    return Math.ceil(currentDay.getTime() / 1000) + (day * 86400)
}

export const timeZoneDifference = (date) => {
    const dateWithMill = new Date(date).getTime()
    const timeZoneDifference = new Date(date).getTimezoneOffset()
    const timeZoneDifferenceWithMill = Math.abs(timeZoneDifference) * 60 * 1000
    return Math.sign(timeZoneDifference) === "-1" ? dateWithMill + timeZoneDifferenceWithMill : dateWithMill - timeZoneDifferenceWithMill
}