import React, { useEffect, useState } from 'react';

export default function ProgressiveImage ({ imgSrc, previewSrc, className }) {

    const [ usedSrc, setUsedSrc ] = useState(previewSrc);
    const [ usedEffectStyle, setUsedEffectStyle ] = useState({ filter: 'blur(5px)', clipPath: 'inset(0)' });

    useEffect(() => {
        const img = new Image();
        img.src = imgSrc;
        img.onload = () => {
            setUsedSrc(img.src);
            setUsedEffectStyle({});
        }
    }, []);

    return <img loading="lazy" className={className} src={usedSrc} style={{ transition: 'filter 0.1s ease-out', ...usedEffectStyle }} />;
}