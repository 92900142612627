import React from "react";
import Button from "../../../../../components/atoms/ButtonV3";

const ChangeStepButtonTemplate = ({changeStep, type, textLink}) => {
    return (
        <div className="auth-step_change-button-template">
            {
                <p className="auth-step_change-button-template_text">
                    {
                        textLink
                            ? <>
                                Don’t want to create a KOMPETE account with email? Then
                                <span onClick={() => changeStep("step4")}> go back to all creation options.</span>.
                            </>
                            : <>Don’t have a KOMPETE account? Create one below!</>
                    }
                </p>
            }
            <Button  onClick={() => type === "create" ? changeStep("step4") : changeStep("step1")} view="outline">
                {
                    type === "create"
                        ? "Create a kompete account"
                        : "Sign in with your account"
                }
            </Button>
        </div>
    )
}
export default ChangeStepButtonTemplate