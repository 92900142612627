import React from "react"
import {BrowserRouter as Router, Route, Routes,} from "react-router-dom";

import Home from "./pages/Home";
import News from "./pages/News";
import Token from "./pages/Token";
import NewsPost from "./pages/News/NewsPosts";
import FAQ from "./pages/FAQ";
import Download from "./pages/Download";
import PageNotFound from "./pages/404";
import BuyKompeteToken from "./pages/Token/HowToBuy";
import Links from "./pages/Links";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import Profile from "./pages/Profile";
import Shop from "./pages/Shop";
import GameEconomy from "./pages/GameEconomy";

const Routers = () => {

    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Download/>}/>
                <Route path="/news" element={<News/>}/>
                <Route path="/token" element={<Token/>}/>
                <Route path="/news/:id" element={<NewsPost/>}/>
                <Route path="/faq" element={<FAQ/>}/>
                <Route path="/download" element={<Download/>}/>
                <Route path="/buy-kompete-token" element={<BuyKompeteToken/>}/>
                <Route path="/shop" element={<Shop/>}/>
                <Route path="/bnb" element={<BuyKompeteToken/>}/>
                <Route path="/links" element={<Links/>}/>
                <Route path="/privacy" element={<PrivacyPolicy/>}/>
                <Route path="/terms" element={<TermsConditions/>}/>
                <Route path="/profile" element={<Profile/>}/>
                <Route path="/economy" element={<GameEconomy/>}/>
                <Route path="*" element={<PageNotFound/>}/>
            </Routes>
        </Router>
    )
}

export default Routers