import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {userRecoverPassword} from "../../../Store/AuthV2/AuthV2Slice";
import {addressReduction} from "../../../Utils/utils";

import Image from "../../atoms/Image";
import Button from "../../../components/atoms/ButtonV3";

import {png} from "../../../assets/png";
import "./style.scss"

const ProfileSetting = ({setCurrentStep}) => {
    const {nickname, wallet, email, username, picture} = useSelector(state => state.authV2.signIn.userData)
    const {success, error, errorMessage} = useSelector(state => state.authV2.recoverPassword)
    const [disable, setDisable] = useState(false)
    const dispatch = useDispatch()
    const isSocialLogin = sessionStorage.getItem("login") === "social"

    const resetPassword = () => {
        setDisable(true)
        dispatch(userRecoverPassword({username: username}))

        setTimeout(() => {
            setDisable(false)
        }, 60000)
    }

    return (
        <section className="profile">
            <div className="profile_container">
                <h1>Profile Settings</h1>
                <div className="profile_container_avatar">
                    <div className="profile_container_avatar_img">
                        <Image alt="avatar" src={picture ? picture : png.NewAvatar}/>
                    </div>
                    <h4 className="profile_container_avatar_name">{nickname}</h4>
                </div>
                <div className="profile_container_info">
                    <ul>
                        <li>
                            <span>NICKNAME</span>
                            <span>{nickname}</span>
                        </li>
                        <li>
                            <span>email address</span>
                            <span>{email}</span>
                        </li>
                        <li>
                            <span>Wallet</span>
                            {
                                wallet ?
                                    <div className="profile_container_info_wallet-text">
                                        <span>{addressReduction(wallet, 5)}</span>
                                        <span>Wallet Connected</span>
                                    </div>
                                    :
                                    <button className="profile_container_info_wallet-btn"
                                            onClick={() => setCurrentStep("profileStep1")}>
                                        Connect A Web3 Wallet
                                    </button>
                            }
                        </li>
                    </ul>
                </div>
                {!isSocialLogin &&
                <div className="profile_container_buttons-block">
                    <div className="profile_container_buttons-block_item">
                        <div className="profile_container_buttons-block_item_head">
                            <span>Change Password</span>
                            <span className="request-info">{success ? "Password requested!" : error ? errorMessage : ""}</span>
                        </div>
                        <Button
                            view="white"
                            onClick={() => resetPassword()}
                            disabled={disable}
                        >
                            Request password
                        </Button>
                        <span className="request-info outside">{success ? "Password requested!" : error ? errorMessage : ""}</span>
                    </div>
                </div>}
            </div>
        </section>
    )
}

export default ProfileSetting