import React from "react";

export const WebsiteRightsContent = ({children}) => {
    return (
        <section className="rights-content">
            <div className="container">
                <div>
                    <div className="rights-content_block">
                        {children}
                    </div>
                </div>
            </div>
        </section>
    )
}
export default WebsiteRightsContent