import React, {useEffect, useState, memo} from "react"

import {useLocation} from "react-router-dom";

import Wrapper from "../PageWrapper";
import Header from "./Header";
import Footer from "./Footer";

import form from "../DefaultLayout/Header/SignIn/Steps/form";

const DownloadLayout = ({children, isFollowUsShow = false, isFooter = true, menu = true}) => {
    const stepDataCopy = JSON.parse(JSON.stringify(form))
    const [stepData, setStepData] = useState(stepDataCopy)

    const location = useLocation()

    useEffect(() => {
        window.scroll(0, 0)
        window.history.scrollRestoration = 'manual'

        if (sessionStorage.getItem("_paq") !== location.pathname) {
            sessionStorage.setItem("_paq", "")
            setTimeout(() => {
                const {_paq} = window
                _paq.push(['setCustomUrl', window.location.href]);
                _paq.push(['setDocumentTitle', document.title]);
                _paq.push(['trackPageView']);
            }, 0)
        }
    }, [location.pathname])

    const changeStep = (step) => {
        const copy = {...stepData}

        setStepData({...copy, currentStep: step})
    }


    const childrenWithProps = React.Children.map(children, child => {
        if (typeof child.type !== 'string') {
            return React.cloneElement(child, {changeStep});
        }
        return child;
    });

    return (
        <Wrapper>
            {
                menu &&
                <Header
                    stepData={stepData}
                    setStepData={setStepData}
                    changeStep={changeStep}
                    stepDataCopy={stepDataCopy}
                />
            }
            <main>
                {childrenWithProps}
            </main>
            {isFooter && <Footer isFollowUsShow={isFollowUsShow}/>}
        </Wrapper>
    )
}

export default memo(DownloadLayout)