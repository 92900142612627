import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux";

import {getFAQ} from "../../../Store/NewsPosts/postSlice";

import DropdownItem from "../../atoms/DropdownItem";

const FaqAskQuestion = () => {
    const {result} = useSelector(state => state.posts.faqs)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!result) dispatch(getFAQ())
    }, [])

    return (
        <section className="faq-questions-container container">
            {
                result?.map((faq, i) =>
                    <DropdownItem key={i} title={faq.question} text={faq.answer}/>
                )
            }
        </section>
    )
}
export default FaqAskQuestion