import React from "react"
import useWindowSize from "../../Hooks/useWindowSize";

import DefaultLayout from "../../layouts/DefaultLayout";
import TokenHero from "../../components/organisms/Token/TokenHero";
import TokenTwo from "../../components/organisms/Token/TokenTwo";
import TokenFour from "../../components/organisms/Token/TokenFour";
import TokenSix from "../../components/organisms/Token/TokenSix";
import TokenThree from "../../components/organisms/Token/TokenThree";
import TokenEight from "../../components/organisms/Token/TokenEight";
import TokenNine from "../../components/organisms/Token/TokenNine";
import TokenFive from "../../components/organisms/Token/TokenFive";
import TokenSeven from "../../components/organisms/Token/TokenSeven";
import TokenERC20 from "../../components/organisms/Token/TokenERC20";
import Seo from "../../components/atoms/Seo";

const Token = () => {
    const {width} = useWindowSize()

    return (
        <DefaultLayout>
            <Seo
                title="KOMPETE Token - 0x1E0b2992079B620AA13A7c2E7c88D2e1E18E46E9"
                description="KOMPETE Token is the official marketplace currency for the KOMPETE Bazaar."
            />
            <TokenHero/>
            {width > 991 ? <TokenERC20/> : <></>}
            <TokenTwo/>
            <TokenFour/>
            <TokenFive/>
            <TokenSix/>
            <TokenSeven/>
            <TokenEight/>
            <TokenThree/>
            <TokenNine/>
        </DefaultLayout>
    )
}

export default Token