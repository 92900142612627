import {expirationTimeCalculator, randomToken} from "../Utils/utils";
import {
    createOrderWithFees, ERC20,
    getERC20Contract, getFeeFromPercent,
    getGameAssetContract,
    getGameAssetFactoryContract,
    getMarketplaceContract,
    OrderSide,
    signOrder
} from "@modernize-games/nft-sdk";
import {parseUnits} from "ethers/lib/utils";
import {Wallet} from "ethers";

const ethers = require('ethers')


const getBalanceETH = async (account, connector) => {
    return connector.getProvider()
        .then(async (result) => {
            const provider = new ethers.providers.Web3Provider(result)
            const balance = await provider.getBalance(account)
            return ethers.utils.formatEther(balance)
        })
        .catch(() => {
            return 0
        })
}

const walletSignature = async (connector) => {
    let balance = 0

    return connector.getProvider()
        .then(async (result) => {
            const signMessage = `Welcome to The Kompete Dashboard,please sign this message to verify your identity.Your custom challenge is: ${randomToken(56)}`
            const provider = new ethers.providers.Web3Provider(result)
            const signer = provider.getSigner()
            const signature = await signer.signMessage(signMessage)
            const verifyAddress = await ethers.utils.verifyMessage(signMessage, signature);

            if (!!verifyAddress) {
                balance = await getBalanceETH(verifyAddress, connector)
            }

            return {isVerify: !!verifyAddress, balance}
        })
        .catch(() => {
            return {isVerify: false, balance}
        })
}

const getConfiguration = async () => {
    const providerRPC = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_CONTRACT_PROVIDER_URL)

    const marketplace = getMarketplaceContract(process.env.REACT_APP_MARKETPLACE_ADDRESS, providerRPC)
    const factory = getGameAssetFactoryContract(process.env.REACT_APP_FACTORY_ADDRESS, providerRPC)
    const collection = getGameAssetContract(process.env.REACT_APP_COLLECTION_ADDRESS, providerRPC)

    const [tokenAddress, treasury] = await Promise.all([
        marketplace.exchangeToken(),
        marketplace.protocolFeeRecipient(),
    ])

    const fees = getFeeFromPercent(2.5 / 100)
    const paymentToken = getERC20Contract(tokenAddress, providerRPC)

    return {
        providerRPC,
        marketplace,
        factory,
        fees,
        collection,
        tokenAddress,
        treasury,
        paymentToken,
    }
}

const loadTokenInfos = async (token: ERC20) => {
    const [symbol, name, decimals] = await Promise.all([
        token.symbol(),
        token.name(),
        token.decimals(),
    ])
    return {
        symbol,
        name,
        decimals,
    }
}

export const createOrderWithSignature = async (
    {
        connector,
        token,
        account,
        price,
        amount,
        expiration,
        tokenId,
        orderSide,
        admin,
    }
) => {
    const {marketplace, collection, paymentToken, treasury, providerRPC} = await getConfiguration()
    const {decimals} = await loadTokenInfos(paymentToken)
    const parsePrice = parseUnits(price, token === "eth" ? 18 : decimals)
    const amounts = [amount]
    const tokenIds = [tokenId]
    const expirationTime = expiration ? expirationTimeCalculator(expiration) : 0
    const fees = getFeeFromPercent(2.5 / 100) // paid by seller (by default)
    const paymentTokenForOrder = token === "eth" ? ethers.constants.AddressZero : paymentToken.address

    const {address, chainId, provider, owner} = await orderOwnerDetails(admin)

    try {
        const order = await createOrderWithFees(
            {
                from: address,
                side: OrderSide[orderSide],
                tokenIds,
                amounts,
                collection: collection.address,
                paymentToken: paymentTokenForOrder,
                price: parsePrice,
                expirationTime
            },
            treasury,
            fees,
        )

        return signOrder(
            marketplace.address,
            chainId,
            order,
            owner,
            await marketplace.nonces(address),
        ).then(signature => {
            return {
                signature,
                order
            }
        })

    } catch (error) {
        return false
    }

    async function orderOwnerDetails(isAdmin) {

        if (isAdmin) {
            const adminWallet = new Wallet(process.env.REACT_APP_SELLER_WALLET_KEY, providerRPC)
            const chainId = (await providerRPC.getNetwork()).chainId

            return {
                address: adminWallet.address,
                chainId,
                owner: adminWallet,
                provider: providerRPC
            }
        } else {
            let connectorProvider = await connector.getProvider()
            let provider = new ethers.providers.Web3Provider(connectorProvider)
            const chainId = (await provider.getNetwork()).chainId
            const owner = provider.getSigner()

            return {
                address: account,
                provider,
                chainId,
                owner
            }
        }
    }
}

const switchWalletToEth = async (library, chainId) => {
    if (chainId !== 1) {
        try {
            await library.send('wallet_switchEthereumChain', [{chainId: '0x1'}])
            return true
        } catch (error) {
            return false
        }
    } else {
        return true
    }
}


export const web3Utils = {
    getBalanceETH,
    walletSignature,
    getConfiguration,
    loadTokenInfos,
    createOrderWithSignature,
    switchWalletToEth
}