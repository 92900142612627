import {gql, GraphQLClient} from "graphql-request/dist";


const endpoint = process.env.REACT_APP_GRAPHCMS_ENDPOINT
const client = new GraphQLClient(endpoint)

const query = gql`
    query getPostsLimited($first:Int)  {
             posts (first: $first, orderBy: date_DESC) {
                  title
                  slug
                  image {
                      url
                      }
                  date
              }
}
    `

//get frequently asked questions

export const getFAQ = async () => {

    const query = gql`
    query GetFAQ {
        faqs{
            answer
            question
        }
       }
    `

    return await client.request(query)
}


//get posts order by quantity
export const getPostsLimited = async (quantity) => {

    const variables = {
        first: quantity
    }

    return await client.request(query, variables)
}

//get all posts
export const getPosts = async () => {
    const query = gql`
    query getPostsLimited {
             posts (orderBy: date_DESC) {
                  title
                  slug
                  image {
                      url
                      }
                  date
              }
}
    `
    return await client.request(query)
}


//get current Post and content
export const getCurrentPostContent = async (slag) => {
    const query = gql`
    query getCurrentPostsContent($slag:String) {
        post(where: {slug: $slag}) {
                title
                slug
                image {
                    url
                }
                date
                author {
                    name
                }
                content {
                    html
                }
            }
    }
    `
    const variables = {
        slag: slag
    }

    return await client.request(query, variables)

}

const postService = {
    getPosts,
    getPostsLimited,
    getCurrentPostContent,
    getFAQ
}

export default postService