import React from "react"
import {png} from "../../../assets/png";
import Image from "../../atoms/Image";
import Title from "../../atoms/TitleTemplate";
import "./style.scss"

const TokenSeven = () => {
    return (
        <section className="token-seven">
                <Image className="c5" alt="coin" src={png.C5}/>
            <div className="container">
                <Title underline={true}>Gain Exclusive <br/><span>access</span></Title>
                <div className="content">
                    <div>
                        <Image alt="badger" src={png.KompeteBadger}/>
                    </div>
                    <div>
                        <div>
                            <div><span>1</span></div>
                            <div>Access closed testing with the devs</div>
                        </div>
                        <div>
                            <div><span>2</span></div>
                            <div>Access exclusive giveaways</div>
                        </div>
                        <div>
                            <div><span>3</span></div>
                            <div>Access exclusive cosmetics drops</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TokenSeven