const host = process.env.REACT_APP_MARKET_SERVER_HOST

const getCurrentDivineNFT = async (thunkAPI) => {
    return fetch(`${host}/v1/item/divine`, {
        method: "POST"
    })
        .then(response => {
            const {status, data} = response
            if (status === 200)
                return response.json()

            if (status === 401)
                throw new Error(data.message)

            throw new Error("Something went wrong")
        })
        .then((result) => {
            return result.data
        })
        .catch(error => {
            return thunkAPI.rejectWithValue(error.message || error)
        });
}

const createNFTBuyOrder = async (thunkAPI, data) => {

    return fetch(`${host}/v1/item/nft/order`, {
        method: "POST",
        body: JSON.stringify(data)
    })
        .then(response => {
            const {status, data} = response
            if (status === 200)
                return response.json()

            if (status === 401)
                throw new Error(data.message)

            throw new Error("Something went wrong")
        })
        .then((result) => {
            return result
        })
        .catch(error => {
            return thunkAPI.rejectWithValue(error.message || error)
        });
}

export const divineShopService = {
    getCurrentDivineNFT,
    createNFTBuyOrder
}