import React, {useEffect} from "react";
import SuccessModal from "../../../components/molecules/TxhModals/SuccessModal";

const ProfileStepTwo = ({show, changeStep}) => {

    useEffect(() => {
        setTimeout(() => {
            changeStep("")
        }, 3000)
    }, [])

    return (
        <SuccessModal
            show={show}
            text1="Success!"
            text2="Web3 Wallet connected"
            isShowClose={false}
        />
    )
}

export default ProfileStepTwo