import React from "react";
import {useDetectMobile} from "../../../Hooks/useDetectMobile";

import ModalWindowTemplate from "../ModalWindowTemplate";
import Button from "../../atoms/ButtonV3";

import {svg} from "../../../assets/svg";
import "./style.scss"

const WalletsModal = ({show, closeClick, buttonClick, connect}) => {
    const {isMobile} = useDetectMobile()
    const isProvider = window.ethereum

    return (
        <ModalWindowTemplate
            show={show}
            title="Select a web3 wallet to connect"
            className="wallet-modal"
            size="modal-lg"
            changeStep={closeClick}
            paddingTop={true}
            powered={true}
            privacy={true}
        >
            {
                isProvider &&
                <WalletButton
                    wallet={"metamask"}
                    img={svg.MetamaskFront}
                    text={"metamask"}
                    onClick={() => connect("metamask")}
                />
            }
            {
                isMobile && isProvider &&
                <WalletButton
                    wallet={"trustWallet"}
                    img={svg.TrustWallet}
                    text={"trust wallet"}
                    onClick={() => connect("trustWallet")}
                />
            }
            <WalletButton
                wallet={"walletConnect"}
                img={svg.ConnectWalletWhite}
                text={"wallet connect"}
                onClick={() => connect("connectWallet")}
            />

            <div className="wallet-modal_button">
                <Button
                    view="outline"
                    onClick={buttonClick}
                >
                    I dont't want to connect
                </Button>
            </div>
        </ModalWindowTemplate>
    )
}

export default WalletsModal

const WalletButton = ({wallet, img, text, onClick}) => {
    return (
        <div className="wallet-button" onClick={() => onClick()}>
            <div className="left">
                <div className={wallet}>
                    <img alt={wallet} src={img}/>
                </div>
                <div className="left_text">
                    {text}
                </div>
            </div>
            <div className="right">
                <span></span>
            </div>
        </div>
    )
}