import Header_02 from "./header_02.png"
import Coming from "./coming.png"
import M2_1 from "./m2-1.png"
import M3_1 from "./m3-1.png"
import BattleRoyal from "./battle-royal.png"
import Basketball from "./basketball.png"
import KartRace from "./kart-race.png"
import GameMap from "./game-map.png"
import GameMapResized from "./game-map-resized.png"
import M7 from "./m7.png"
import M8 from "./m8.png"
import GuyDribbling from "./guy-dribbling.png"
import M10 from "./m10.png"
import M11 from "./m11.png"
import M18 from "./m18.png"
import ComingBrand from "./coming-details.png"
import FollowUs from "./follow-us-bg.png"
import M2_3 from "./m2-3.png"
import M2_3_Mob from "./m2-3-mob-sentator.png"
import M2_1_Mob from "./m2-1-mob-sentator-02.png"
import M2_2_Mob from "./m2-2-mob-sentator.png"
import M6_Mob from "./m6-mob-sentator-02.png"
import M7_2 from "./m7_2.png"
import T1 from "./t1.png"
import T2 from "./t2.png"
import T3 from "./t3.png"
import T4 from "./t4.png"
import T5 from "./t5.png"
import T6 from "./t6.png"
import T7 from "./t7.png"
import T8 from "./t8.png"
import T9 from "./t9.png"
import T10 from "./t10.png"
import T11 from "./t11.png"
import T12 from "./t12.png"
import T13 from "./t13.png"
import T142 from "./t142.png"
import T141 from "./t142.png"
import C1 from "./c1.png"
import C2 from "./c2.png"
import C3 from "./c3.png"
import C4 from "./c4.png"
import C5 from "./c5.png"
import T15 from "./t15.png"
import CopyClipboard from "./copyClipboard.png"
import FinalMontana from "./FinalMontage.png"
import FinalMontanaV2 from "./FinalMontanaV2.png"
import Video from "./video.png"
import GoldEtherum from "./gold-etherum.png"
import TokenFloating from "./token floating .png"
import NewAvatar from "./new-avatar.png"
import LogoKompete from "./Logo_KOMPETE.png"
import LogoModernizeGame from "./T_Logo_ModernizeGames.png"
import WhiteTemplate from "./whiteExample.png"
import KompeteBadger from "./kompete_badger.png"
import Marketplace from "./marketplace-cosmetics.png"
import newLogo from "./newLogo.png"
import Check from "./Check.png"
import Aiming_org from "./Aiming_Sit.png"
import Aiming_resize from "./Aiming_Sit_Resize.png"
import FinalMontana_resize from "./FinalMontage-resize.png"
import FinalMontanaV2_resize from "./FinalMontanaV2-resize.png"
import NotFound from "./404.png"
import FirstScreen from "./firstScreen.png"
import FirstScreenResize from "./firstScreen-resize.png"
import KompeteMain from "./kompete-main.png"
import KompeteMainResized from "./kompete-main-resized.png"
import Hero from "./hero.png"
import HeroResized from "./hero-resized.png"
import Hero2 from "./hero-character-2.png"
import Hero2Resized from "./hero-character-2-resized.png"
import HeroThree from "./hero-three.png"
import HeroThreeResized from "./hero-three-resized.png"
import Interrogative from "./interrogative.png"
import Hero3 from "./hero-character-3.png"
import Hero3Resized from "./hero-character-3-resized.png"
import HeroMobile from "./hero-character-mobile.png"
import HeroMobileResized from "./hero-character-mobile-resized.png"
import Golf from "./golf.png"
import GolfMobile from "./golf-mobile.png"
import Basketball1 from "./basketball-1.png"
import BasketballMobile from "./basketball-mobile.png"
import HeroNews from "./hero-news.png"
import HeroNewsResized from "./hero-news-resized.png"
import HeroTerms from "./hero-terms.png"
import HeroTermsResized from "./hero-terms-resized.png"
import GolfResized from "./golf-resized.png"
import GolfMobileResized from "./golf-mobile-resized.png"
import Basketball1Resized from "./basketball-1-resized.png"
import BasketBallMobileResized from "./basketball-mobile-resized.png"
import HeroTernsMobile from "./hero-terms-mobile.png"
import HeroTermsMobileResized from "./hero-terms-mobile-resized.png"
import HeroIndividual1 from "./hero-Individual-1.png"
import HeroIndividual2 from "./hero-Individual-2.png"
import HeroIndividual3 from "./hero-Individual-3.png"
import HeroIndividual4 from "./hero-Individual-4.png"
import HeroIndividual5 from "./hero-Individual-5.png"
import EthereumEco from "./ethereum-eco.png"
import VoolahEco from "./voolah-eco.png"
import KompeteTokenStacked from "./kompete-token-stacked.png"
import HeroEco from "./hero-eco.png"
import HeroEcoMain from "./hero-eco-main.png"
import HeroEcoMainResized from "./hero-eco-main-resized.png"
import RarityGroup from "./rarity-group.png"
import Xbox from "./Xbox_download.png"
import XboxMobile from "./Xbox_download_mobile.png"
import Playstation from "./Playstation_download.png"
import PlaystationMobile from "./Playstation_download_mobile.png"
import GooglePlay from "./GooglePlay_download.png"
import GooglePlayMobile from "./GooglePlay_download_mobile.png"
import AppStore from "./AppStore_download.png"
import AppStoreMobile from "./AppStore_download_mobile.png"

//mock img
import MockImg1 from "./mockImg.png"

export const png = {
    Header_02,
    Coming,
    M2_1,
    M3_1,
    Golf,
    Basketball,
    BattleRoyal,
    KartRace,
    GameMap,
    M7,
    M8,
    GuyDribbling,
    M10,
    M11,
    M18,
    ComingBrand,
    FollowUs,
    M2_3,
    M2_3_Mob,
    M2_2_Mob,
    M2_1_Mob,
    M6_Mob,
    M7_2,
    T1,
    T2,
    T3,
    T4,
    T5,
    T6,
    T7,
    T8,
    T9,
    T10,
    T11,
    T12,
    T13,
    T141,
    T142,
    C1,
    C2,
    C3,
    C4,
    C5,
    T15,
    CopyClipboard,
    FinalMontana,
    FinalMontanaV2,
    Video,
    GoldEtherum,
    TokenFloating,
    NewAvatar,
    LogoKompete,
    LogoModernizeGame,
    WhiteTemplate,
    KompeteBadger,
    Marketplace,
    newLogo,
    MockImg1,
    Check,
    Aiming_org,
    Aiming_resize,
    FinalMontana_resize,
    FinalMontanaV2_resize,
    NotFound,
    FirstScreen,
    FirstScreenResize,
    KompeteMain,
    KompeteMainResized,
    Hero,
    HeroResized,
    Hero2,
    Hero2Resized,
    HeroThree,
    HeroThreeResized,
    Interrogative,
    GameMapResized,
    Hero3,
    Hero3Resized,
    HeroMobile,
    GolfMobile,
    Basketball1,
    BasketballMobile,
    HeroNews,
    HeroNewsResized,
    HeroTerms,
    HeroTermsResized,
    HeroMobileResized,
    GolfResized,
    GolfMobileResized,
    Basketball1Resized,
    BasketBallMobileResized,
    HeroTernsMobile,
    HeroTermsMobileResized,
    HeroIndividual1,
    HeroIndividual2,
    HeroIndividual3,
    HeroIndividual4,
    HeroIndividual5,
    EthereumEco,
    VoolahEco,
    KompeteTokenStacked,
    HeroEco,
    HeroEcoMain,
    HeroEcoMainResized,
    RarityGroup,
    Xbox,
    XboxMobile,
    Playstation,
    PlaystationMobile,
    AppStore,
    AppStoreMobile,
    GooglePlay,
    GooglePlayMobile
}