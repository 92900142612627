import React from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import DivineShop from "../../components/organisms/Shop/DivineShop";
import Seo from "../../components/atoms/Seo";

const Shop = () => {
    return (
        <DefaultLayout>
            <Seo
                title="Shop - KOMPETE"
                description="The KOMPETE shop. Buy KOMPETE cosmetics online at our online shop."
            />
            <DivineShop isShowCosmetic={false} isMint={false}/>
        </DefaultLayout>
    )
}
export default Shop