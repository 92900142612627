import React, {useMemo} from "react"
import {useNavigate} from "react-router-dom"
import {useWeb3React} from "@web3-react/core";
import {useDispatch, useSelector} from "react-redux";

import {createNFTBuyOrder} from "../../../../Store/DivineShop/divineShopSlice";

import {formatBalance} from "../../../../Utils/utils";
import {divineUtils} from "./utils";
import {web3Utils} from "../../../../web3/utils";
import {setData} from "../../../../Store/AuthV2/AuthV2Slice";

import ModalWindowTemplate from "../../../molecules/ModalWindowTemplate";
import Image from "../../../atoms/Image";
import PaymentMethod from "../../../molecules/PaymentMethodTemplate";
import Button from "../../../atoms/ButtonV3";

import {svg} from "../../../../assets/svg";
import "./style.scss"

const DivineOrderModal = ({show, setWhichModalOpen, quantity}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {active, account, connector} = useWeb3React()
    const {ethBalance, wallet} = useSelector(state => state.authV2.signIn.userData)
    const {userData} = useSelector(state => state.authV2.signIn)
    const {divine} = useSelector(state => state.divineShop)
    const totalPrice = quantity * divine?.data.price
    const isInsufficient = +totalPrice >= +ethBalance

    const currentClick = useMemo(() => {
        return {
            onClick: () =>
                !active && !account
                    ? setWhichModalOpen("wallet")
                    : !isInsufficient
                    ? buyCosmetic()
                    : navigate("/buy-kompete-token", {state: {process: "order-more-eth"}}),
            text: !active && !account ? "connect wallet" : !isInsufficient ? "pay with eth" : "buy eth"
        }
    }, [account, active])

    function buyCosmetic() {
            if (account.toLowerCase() !== wallet.toLowerCase()) {
                setWhichModalOpen("reject-wallet")
            } else {
                setWhichModalOpen("process")
                divineUtils.mintOrderForCosmetic({
                    connector,
                    price: totalPrice.toString(),
                    tokenId: divine.data.token_id,
                    quantity,
                    account,
                    hashCallBack
                })
                    .then((result) => {
                        if (result) {
                            setWhichModalOpen("success")
                            web3Utils.getBalanceETH(account, connector)
                                .then((balance) => {
                                    const newData = {...userData, ethBalance: balance}
                                    dispatch(setData({path1: "signIn", path2: "userData", data: newData}))
                                })
                        } else {
                            setWhichModalOpen("reject")
                        }
                    })
                    .catch((error) => {
                        setWhichModalOpen("reject")
                    })
            }
    }

    function hashCallBack(transactionDetails) {
        dispatch(createNFTBuyOrder({sku: divine?.data?.sku, quantity, transaction: transactionDetails.hash,jwt:userData.accessToken}))
    }

    return (
        <ModalWindowTemplate
            show={show}
            title="Order Summery"
            isShowClose={false}
            size="modal-lg"
            className="shop-divine_order-summery"
            powered={true}
        >
            <div className="shop-divine_order-summery_content">
                <div className="shop-divine_order-summery_content_info">
                    <div className="shop-divine_order-summery_content_info_img">
                        <Image alt="cosmetic" src={divine?.data?.image_url}/>
                    </div>
                    <div className="shop-divine_order-summery_content_info_price">
                        <div className="shop-divine_order-summery_content_info_price_img eth">
                            <Image src={svg.EtheriumGold} alt="currency"/>
                        </div>
                        <div className="shop-divine_order-summery_content_info_price_value">
                            {totalPrice}
                        </div>
                    </div>
                </div>
                <div className="shop-divine_order-summery_content_payments-methods">
                    <div className="shop-divine_order-summery_content_payments-methods_head">
                        <span className="shop-divine_order-summery_content_payments-methods_head_quantity">
                            Available payment methods (1)
                        </span>
                        {
                            account && isInsufficient &&
                            <span className="shop-divine_order-summery_content_payments-methods_head_error">
                             Insufficient ETH balance. Please purchase more ETH!
                            </span>
                        }
                    </div>
                    <PaymentMethod
                        method="eth"
                        insufficient={account ? isInsufficient : false}
                        checked={true}
                        balance={ethBalance ? formatBalance(ethBalance, 3) : 0}
                    />
                </div>
                <div className="shop-divine_order-summery_content_total">
                    <div>Total</div>
                    <div>{totalPrice} ETH</div>
                </div>
                {
                    isInsufficient &&
                    <div className="shop-divine_order-summery_content_mobile-error">
                        Insufficient ETH balance. Please purchase more ETH!
                    </div>
                }
                <div className="shop-divine_order-summery_content_buttons">
                    <Button view="opacity" onClick={() => setWhichModalOpen("")}>Cancel</Button>
                    <Button
                        view="secondary"
                        onClick={currentClick.onClick}
                    >
                        {currentClick.text}
                    </Button>
                </div>
            </div>
        </ModalWindowTemplate>
    )
}

export default DivineOrderModal