import React, {useState} from "react";
import classNames from "classnames";

import Image from "../Image";

import "./style.scss"
import {svg} from "../../../assets/svg";

const Input = ({name, placeholder, value, type, label, labelClick, labelClickText, error, onChange, status, size}) => {
    const inpStyle = classNames(status ?? "default", size ?? "lg")

    const [passwordFiled, setPasswordFiled] = useState({type: "password", check: false})

    const changeType = () => {
        const copy = {...passwordFiled}
        let newType = copy.type === "password" ? "text" : "password"
        setPasswordFiled({...copy, type: newType, check: !copy.check})
    }

    return (
        <div className="inp-container">
            <div className={`inp-container_label-block ${status}`}>
                {
                    label && <label className="inp-container_label-block_name">{label}</label>
                }
                {
                    error && <span className={`inp-container_label-block_error ${status}`}>{error}</span>
                }
                {
                    labelClick && <button className="inp-container_label-block_btn" onClick={labelClick}>{labelClickText}</button>
                }
            </div>
            <input
                type={type === "password" ? passwordFiled.type : type}
                name={name}
                placeholder={placeholder}
                value={value}
                autoComplete="off"
                onChange={onChange}
                className={inpStyle}
            />
            {
                type === "password" &&
                <div onClick={changeType} className="inp-container_password-icon">
                    {
                        passwordFiled.type === "password"
                            ? <Image src={svg.PasswordClose}/>
                            : <Image src={svg.PasswordOpen}/>
                    }
                </div>
            }
        </div>
    )
}
export default Input