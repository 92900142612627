import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {useWeb3React} from "@web3-react/core";

import SuccessModal from "../../../../../components/molecules/TxhModals/SuccessModal";

const StepWalletSuccess = ({show, changeStep, registerUserDispatch}) => {
    const {signUp} = useSelector(state => state.authV2)
    const {signUpSocial} = useSelector(state => state.authV2)
    const {registration} = useSelector(state => state.authV2.userVerification)
    const registrationType = registration ? "social" : "regular"
    const {account, deactivate} = useWeb3React()

    useEffect(() => {
        registerUserDispatch(account,registrationType)
    }, [])

    useEffect(() => {
        if (signUp.error) {
            changeStep("step5")
        }
        if (signUp.success) {
            changeStep("step7")
        }
        if (signUpSocial.success) {
            changeStep("step1")
        }
        deactivate()
    }, [signUp.error, signUp.success, signUpSocial.success])

    return (
        <SuccessModal
            show={show}
            text1="Success!"
            text2="Web3 Wallet connected"
            isShowClose={false}
        />
    )
}

export default StepWalletSuccess