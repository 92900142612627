import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";

import {getPosts, getFirstNinePosts} from "../../../Store/NewsPosts/postSlice";

import LoadSpinner from "../../atoms/LoadSpinner";
import Button from "../../atoms/ButtonV3";
import Title from "../../atoms/TitleTemplate";
import ArticleCart from "../../atoms/ArticleCart";
import "./style.scss"

const NewsContent = () => {
    const dispatch = useDispatch()
    const {posts, loading} = useSelector(state => state.posts)

    const buttonStyle = classNames("news_content-container_news_more-btn", {hidden: posts?.length > 9})

    useEffect(() => {
        dispatch(getFirstNinePosts(9))
    }, [])

    const showAll = () => {
        dispatch(getPosts())
    }

    return (
        <section className="news_content-container container">
            <Title underline={true}>Here's <span>what's happening</span></Title>
            {
                loading && !posts
                    ? <div className="news_content-container_loading"><LoadSpinner/></div>
                    : <div className="news_content-container_news">
                        <div>
                            {
                                posts?.map((post, i) =>
                                    <ArticleCart linkTo={`/news/${post.slug}`} key={i} text={post.title} date={post.date}
                                                 img={post.image.url}/>
                                )
                            }
                        </div>
                        <div className={buttonStyle}>
                            <Button view="outline" onClick={showAll}>view all</Button>
                        </div>
                    </div>
            }

        </section>
    )
}

export default NewsContent