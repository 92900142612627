import React from "react"
import {useNavigate} from "react-router-dom";

import Button from "../../atoms/ButtonV3";
import Image from "../../atoms/Image";
import Title from "../../atoms/TitleTemplate";

import {png} from "../../../assets/png";
import "./style.scss"

const TokenSix = () => {
    const navigate = useNavigate()

    return (
        <section className="token-six">
            <Image className="c1" alt="coin" src={png.C1}/>
            <Image className="c3" alt="coin" src={png.C3}/>
            <Image className="c4" alt="coin" src={png.C4}/>
            <Image className="c2" alt="coin" src={png.C2}/>
            <div className="container">
                <Title underline={true}>Kompete Token<br/><span>utility</span></Title>
                <div className="content">
                    <div>
                        <h3>Use Kompete token <br/><span> on the bazaar</span></h3>
                        <p>Players can facilitate transactions on our cosmetic marketplace, known as The Bazaar. The
                            Bazaar is an open market where players can buy or sell their cosmetics with each other!
                        </p>
                        <Button view="secondary" onClick={() => navigate("/token")}>Baazar coming soon</Button>
                    </div>
                    <div>
                        <Image alt="white" src={png.Marketplace}/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TokenSix