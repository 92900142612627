import {InjectedConnector} from "@web3-react/injected-connector"
import {WalletConnectConnector} from "@web3-react/walletconnect-connector";

const metamask = new InjectedConnector({
    supportedChainIds: [1, 11155111, 56, 137, 42161, 10, 43114, 250, 25, 2222]
})

const connectWallet = new WalletConnectConnector({
    rpcUrl: process.env.REACT_APP_CONTRACT_PROVIDER_URL,
    qrcode: true,
})

const trustWallet = new InjectedConnector({
    supportedChainIds: [1, 11155111, 56, 137, 42161, 10, 43114, 250, 25, 2222]
})

export const connectors = {
    metamask: metamask,
    connectWallet: connectWallet,
    trustWallet: trustWallet
}