import React from "react";
import PageMain from "../../atoms/PageMain";
import {png} from "../../../assets/png";
import "./style.scss"

const FaqMain = () => {
    return (
        <PageMain
            page="faq"
            originalImage={png.Aiming_org}
            resizeImage={png.Aiming_resize}
            title="FAQ"
            text={<p>Frequently Asked Questions</p>}
        />
    )
}

export default FaqMain