import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useWeb3React} from "@web3-react/core";

import {connectors} from "../../../../web3/connectors";
import {web3Utils} from "../../../../web3/utils";
import {setData, reset, userAddWallet} from "../../../../Store/AuthV2/AuthV2Slice";

import DivineOrderModal from "./DivineOrderModal";
import RejectModal from "../../../molecules/TxhModals/RejectModal";
import ProcessModal from "../../../molecules/TxhModals/ProcessModal";
import DivineListingUpdate from "./DivineListingUpdate";
import WalletsModal from "../../../molecules/WalletModal";

const DivineSteps = ({whichModalOpen, setWhichModalOpen, quantity}) => {
    const {activate, active, account, connector, deactivate} = useWeb3React()
    const [isConnectWallet, setIsConnectWallet] = useState({status: false, provider: ""})
    const {userData} = useSelector(state => state.authV2.signIn)
    const {success, loading} = useSelector(state => state.authV2.addWallet)
    const dispatch = useDispatch()

    useEffect(() => {
        if (active && account && isConnectWallet.status) {
            setWhichModalOpen("process")
            web3Utils.walletSignature(connector)
                .then((result) => {
                    const {isVerify, balance} = result
                    if (isVerify) {
                        const newData = {...userData, ethBalance: balance}
                        dispatch(setData({path1: "signIn", path2: "userData", data: newData}))
                        if (!userData.wallet) {
                            dispatch(userAddWallet({token: userData.accessToken, wallet: account}))
                        } else {
                            setWhichModalOpen("order")
                        }
                    } else {
                        setWhichModalOpen("reject")
                        setTimeout(() => {
                            setWhichModalOpen("wallets")
                        }, 2000)
                        deactivate()
                    }
                })
        }
    }, [active, account])

    useEffect(() => {
        if (isConnectWallet.status) {
            setIsConnectWallet({status: false, provider: ""})
            if (success && userData.wallet) {
                setWhichModalOpen("order")
                dispatch(reset("addWallet"))
            } else {
                setWhichModalOpen("reject")
                deactivate()
            }
        }
    }, [success])

    const connectWallet = (provider) => {
        activate(connectors[provider])
        setIsConnectWallet({status: true, provider: provider})
    }

    return (
        <React.Fragment>
            {
                {
                    "order":
                        <DivineOrderModal
                            show={whichModalOpen === "order"}
                            setWhichModalOpen={setWhichModalOpen}
                            quantity={quantity}
                        />,
                    "reject":
                        <RejectModal
                            show={whichModalOpen === "reject"}
                            text1="Oooops!"
                            text2="Web3 Wallet failed to connect"
                            isShowClose={true}
                            closeClick={setWhichModalOpen}
                        />,
                    "reject-wallet":
                        <RejectModal
                            show={whichModalOpen === "reject-wallet"}
                            text1="Oooops!"
                            text2="The web3 wallet you connected doesn't match the registered wallet"
                            isShowClose={true}
                            closeClick={setWhichModalOpen}
                        />,
                    "process":
                        <ProcessModal
                            show={whichModalOpen === "process"}
                            text1="Waiting for signature..."
                            text2="After wallet approval, your transaction will be finished shortly."
                        />,
                    "success":
                        <DivineListingUpdate
                            show={whichModalOpen === "success"}
                            setWhichModalOpen={setWhichModalOpen}
                        />,
                    "wallet":
                        <WalletsModal
                            show={whichModalOpen === "wallet"}
                            cancelClick={setWhichModalOpen}
                            connect={connectWallet}
                            closeClick={setWhichModalOpen}
                            buttonClick={() => setWhichModalOpen("")}
                        />
                }[whichModalOpen]
            }
        </React.Fragment>
    )
}
export default DivineSteps