import React from "react"
import "./style.scss"
import classNames from "classnames";

const url = {
    contract: "https://etherscan.io/token/0x1E0b2992079B620AA13A7c2E7c88D2e1E18E46E9",
    uniswap: "https://app.uniswap.org/#/swap?outputCurrency=0x1E0b2992079B620AA13A7c2E7c88D2e1E18E46E9",
    chart: "https://www.coinscan.com/tokens/eth/kompete"
}
const TokenERC20 = () => {

    return (
        <div className="ERC20">
            <h2>TOKEN now Deployed on erc-20</h2>
            <div className="ERC20_content">
                <ERC20Card link={url.contract} type="contract"/>
                <ERC20Card link={url.uniswap} type="uniswap"/>
                <ERC20Card link={url.chart} type="chart"/>
            </div>
        </div>
    )
}
export default TokenERC20

const ERC20Card = ({link, type}) => {
    const cardStyle = classNames("ERC20-card_img", type)

    return (
        <div className="ERC20-card" onClick={() => window.open(link, "_blank")}>
            <div className="ERC20-card_content">
                <h4>{type}</h4>
                <span>ERC-20</span>
            </div>
            <div className={cardStyle}></div>
        </div>
    )
}