import React from "react"
import {useSelector} from "react-redux";
import LoadSpinner from "../../atoms/LoadSpinner";
import SignIn from "./SignIn";
import KompeteToke from "./KompeteToke";
import FooterModernize from "../../atoms/ModernizeFooter";

const ProtectedPage = ({checkBalance = false, children, changeStep}) => {
    const {success,userData} = useSelector(state => state.authV2.signIn)
    const authToken = window.sessionStorage.accessToken

    return (
        <React.Fragment>
            {
                checkBalance ?
                    authToken ?
                        success ?
                            userData.kompeteBalance !== null ?
                                userData.kompeteBalance >= 0.5 ?
                                    children
                                    :
                                    <div className="protected-container">
                                        <KompeteToke changeStep={changeStep}/>
                                        <FooterModernize/>
                                    </div>
                                :
                                <div className="spinner-container"><LoadSpinner/></div>
                            :
                            <div className="spinner-container"><LoadSpinner/></div>
                        :
                        <div className="protected-container">
                            <KompeteToke changeStep={changeStep}/>
                            <FooterModernize/>
                        </div>
                    :
                    authToken ?
                        success && authToken ? children : <div className="spinner-container"><LoadSpinner/></div>
                        :
                        <div className=" container protected-container"><SignIn changeStep={changeStep}/></div>
            }
        </React.Fragment>
    )
}
export default ProtectedPage
