import React from "react";
import {useSelector} from "react-redux";
import useWindowSize from "../../../Hooks/useWindowSize";

import UnderLine from "../../atoms/UnderLine";
import Button from "../../atoms/ButtonV3";
import ReactPlayer from "../../atoms/ReactPlayer";

import {png} from "../../../assets/png";
import Image from "../../atoms/Image";
import "./style.scss"

const DownloadMain = ({changeStep}) => {
    const {width} = useWindowSize()
    const {signIn} = useSelector(state => state.authV2)
    const accessToken = sessionStorage.getItem("accessToken")

    return (
        <section className="download_main">
            <div className="download_main_filter"/>
            <div className="download_main_content container">
                <div className="download_main_content_info">
                    <h1>Download <span>for free</span></h1>
                    <UnderLine/>
                    <div className="download_main_content_info_video-mobile">
                        <GameVideo/>
                    </div>
                    <h2>Welcome to early access!</h2>
                    <p>KOMPETE is now in Early Access on Windows PC. You can download it below. We have more systems coming
                        soon!</p>
                    <Button
                        view="secondary"
                        icon="window"
                        iconDirection="left"
                        path={true}
                        onClick={() =>
                            accessToken && signIn.success
                                ? window.open('https://www.modernizegames.com/KOMPETE%20Installer.exe',)
                                : changeStep("step1")
                        }
                    >
                        {accessToken && signIn.success ? 'Download on windows' : 'Sign in to download on windows'}
                    </Button>
                    <div className="download_main_content_info_coming-soon">
                        {width > 480 &&
                        <React.Fragment>
                            <Image alt="app-store" src={png.AppStore}/>
                            <Image alt="google-play" src={png.GooglePlay}/>
                            <Image alt="playstation" src={png.Playstation}/>
                            <Image alt="xbox" src={png.Xbox}/>
                        </React.Fragment>}
                        {width < 480 &&
                        <React.Fragment>
                            <Image alt="app-store" src={png.AppStoreMobile}/>
                            <Image alt="google-play" src={png.GooglePlayMobile}/>
                            <Image alt="playstation" src={png.PlaystationMobile}/>
                            <Image alt="xbox" src={png.XboxMobile}/>
                        </React.Fragment>}
                    </div>
                </div>
                <div className="download_main_content_video">
                    <GameVideo/>
                </div>
            </div>
        </section>
    )
}
export default DownloadMain

const GameVideo = () => {
    return <ReactPlayer
        src="https://player.vimeo.com/video/804772625?h=6e5d05f3f7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&loop=1&controls=0"
        title="VSL_02_4K.mp4"
    />
}
