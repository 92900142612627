import React, {useState} from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import ProfileSetting from "../../components/organisms/Profile/Profile";
import ProfileSteps from "../../components/organisms/Profile/ProfileSteps";
import ProtectedPage from "../../components/molecules/ProtectedPage";
import Seo from "../../components/atoms/Seo";

const Profile = () => {
    const [currentStep, setCurrentStep] = useState("")

    return (
        <DefaultLayout>
            <Seo
                title="Profile - KOMPETE"
                description="Make updates to your KOMPETE profile."
            />
            <ProtectedPage>
                <ProfileSetting
                    setCurrentStep={setCurrentStep}
                />
                <ProfileSteps
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                />
            </ProtectedPage>
        </DefaultLayout>
    )
}

export default Profile