import React, {memo} from "react"
import classNames from "classnames";

import Share from "../Share";
import Image from "../../atoms/Image";

import "./style.scss"

const CosmeticViewTemplate = ({classname, img, title, children, loading}) => {

    const CosmeticImg = classNames("left", {skeleton: loading})
    const CosmeticName = classNames("title", {skeleton: loading})

    return (
        <div className={`${classname} shop-template`}>
            <div className={CosmeticImg}>
                {
                    img && <Image alt="cosmetic" src={img}/>
                }
            </div>
            <div className="right">
                <div className="right_header">
                    <h2 className={CosmeticName}>{title}</h2>
                    <div className="right_header_share-block">
                        <Share/>
                    </div>
                </div>
                {children}
            </div>
        </div>
    )
}

export default memo(CosmeticViewTemplate)