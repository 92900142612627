import {configureStore} from "@reduxjs/toolkit"
import postReducer from "./NewsPosts/postSlice"
import authV2Reducer from "./AuthV2/AuthV2Slice"
import globalReducer from "./Global/globalSlice"
import divineShopReducer from "./DivineShop/divineShopSlice"

export const store = configureStore({
    reducer: {
        global: globalReducer,
        posts: postReducer,
        authV2: authV2Reducer,
        divineShop: divineShopReducer
    },

    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})