import React, {useEffect, useRef, useState} from "react";
import * as ReactDOM from "react-dom"
import useWindowSize from "../../../Hooks/useWindowSize";

import Image from "../Image";
import classNames from "classnames";

import {svg} from "../../../assets/svg";
import "./style.scss"

const ModalWindow = ({show, isShowClose, closeClick, size, paddingTop, children}) => {
    const [isHeightSmall, setIsHeightSmall] = useState(false)
    const {height} = useWindowSize()
    const modalRef = useRef(null)

    const modalType = classNames("modal-window", size ?? "modal-lg", {top: paddingTop, "fix-top": isHeightSmall})

    useEffect(() => {
        document.body.style.overflow = "hidden"
        document.body.style.paddingRight = "7px"
        return () => {
            document.body.style.overflow = "unset"
            document.body.style.paddingRight = "0"
        }
    }, [])

    useEffect(() => {
        if (height < modalRef.current.offsetHeight) {
            setIsHeightSmall(true)
        } else {
            setIsHeightSmall(false)
        }
    }, [height])

    return (
        <React.Fragment>
            {
                show &&
                ReactDOM.createPortal(
                    <div className="modal-container">
                        <div ref={modalRef} className={modalType}>
                            {
                                isShowClose &&
                                <Image
                                    className="close-icon"
                                    alt="close"
                                    onClick={() => closeClick(false)}
                                    src={svg.CloseSmall}/>
                            }
                            {children}
                        </div>
                    </div>
                    , document.querySelector("body"))
            }
        </React.Fragment>
    )
}
export default ModalWindow