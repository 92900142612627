import React from "react";
import "./style.scss"

const SkeletonText = ({textLineCount}) => {

    const generateLine = () => {
        let lineCount = []
        for (let i = 0; i < textLineCount; i++) {
            lineCount.push("")
        }

        return lineCount.map((item, i) =>
            <div className='skeleton-text skeleton' key={i}></div>
        )
    }

    return (
        <div className="text-skeleton-block">
            {generateLine()}
        </div>
    )
}

export default SkeletonText