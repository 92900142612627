import React from "react";
import Button from "../../atoms/ButtonV3";
import Title from "../../atoms/TitleTemplate";
import Image from "../../atoms/Image";

import {png} from "../../../assets/png";
import "./style.scss"

const TokenEight = () => {
    return (
        <section className="token-eight">
            <div className="container">
                <Title underline={true}>Be sure to <span>read<br/>Our whitePaper?</span></Title>
                <div className="book">
                    <Image alt="book" src={png.T142}/>
                </div>
                <div className="book-box">
                    <div className="book-box_title">
                        <h3>Whitepaper V1</h3>
                        <span>Now Released!</span>
                    </div>
                    <div className="book-box_text">
                        <p>Read our whitepaper for a breakdown of the game economy, technical details, and our plans for the
                            future!</p>
                        <Button
                            view="secondary"
                            text="read now"
                            onClick={() => window.open("https://modernize-games.gitbook.io/kompete-token-whitepaper/", "_blank")}
                        >
                            Read now
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default TokenEight