import React from "react";
import Title from "../../atoms/TitleTemplate";
import Button from "../../atoms/ButtonV3";
import Image from "../../atoms/Image";

import {png} from "../../../assets/png";
import "./style.scss"

const EconomyBeSure = () => {
    return (
        <section className="economy_be-sure">
            <Title underline={true}>Be sure to <span>read the game economy breakdown!</span></Title>
            <div className="economy_be-sure_content">
                <div className="container">
                    <div className="economy_be-sure_content_info">
                        <h3>Get a full breakdown.</h3>
                        <p>Want more details about how the game economy works? Then be sure to read our breakdown!</p>
                        <Button
                            view="secondary"
                            onClick={() => window.open(`${window.location.origin + '/images/gameEconomyOverview.png'}`)}
                        >
                            Read now
                        </Button>
                    </div>
                    <div className="economy_be-sure_content_img-block">
                        <Image alt="hero" src={png.HeroEco}/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EconomyBeSure