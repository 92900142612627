import React from "react";
import {Link} from "react-router-dom"
import ModalWindowTemplate from "../../../molecules/ModalWindowTemplate";
import Button from "../../../atoms/ButtonV3";
import Image from "../../../atoms/Image";
import Rarity from "../../../atoms/Rarity";
import {png} from "../../../../assets/png";
import {useSelector} from "react-redux";

const DivineListingUpdate = ({show, setWhichModalOpen}) => {
    const {divine} = useSelector(state => state.divineShop)

    return (
        <ModalWindowTemplate
            title="Listing update"
            show={show}
            isShowClose={false}
            size="modal-lg"
            className="shop-divine_listing-update"
        >
            <div className="shop-divine_listing-update_content">
                <div className="shop-divine_listing-update_content_info">
                    <div className="shop-divine_listing-update_content_info_img">
                        <Image alt="cosmetic" src={divine?.data?.image_url}/>
                    </div>
                    <div className="shop-divine_listing-update_content_info_name">
                        <h4>Founders Suit</h4>
                        <div className="shop-divine_listing-update_content_info_name_rarity">
                            <span>Outfit</span>
                            <Rarity
                                text="divine"
                                rarity="divine"
                                size="lg"
                            />
                        </div>
                    </div>
                </div>
                <Button view="opacity" onClick={() => setWhichModalOpen("")}>Return Shop</Button>
                <p className="shop-divine_listing-update_content_terms">
                    By clicking “List Now”, you agree to <Link to="/terms">Terms of service</Link>
                </p>
            </div>
        </ModalWindowTemplate>
    )
}

export default DivineListingUpdate