import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import jwt_decode from "jwt-decode";
import classNames from "classnames";
import {useWeb3React} from "@web3-react/core";

import {reset, setData, userGetData, userVerificationSocial} from "../../../Store/AuthV2/AuthV2Slice"
import navPaths from "./navPathData.json"

import LoadSpinnerAllPage from "../../../components/atoms/LoadSpinner/LoadSpinnerAllPage";
import UserLoginDisplay from "./UserLoginDisplay";
import MenuMobile from "./menuMobile";
import AuthV2Steps from "./SignIn";
import NavItem from "./ResuableComponents/NavItem";
import Image from "../../../components/atoms/Image";

import {png} from "../../../assets/png";
import "./style.scss"

const Header = ({stepData, setStepData, changeStep, stepDataCopy}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [mobileMenu, setMobileMenu] = useState({open: false, section: ""})
    const {userData} = useSelector(state => state.authV2.signIn)
    const {userDataXsolla} = useSelector(state => state.authV2)
    const {signUp} = useSelector(state => state.authV2)
    const {userVerification} = useSelector(state => state.authV2)
    const {changeNickname} = useSelector(state => state.authV2)
    const {deactivate} = useWeb3React()

    const menuHeader = classNames("header", {"mobile-menu": mobileMenu.open && mobileMenu.section === "menu"})

    useEffect(() => {
        const token = sessionStorage.getItem("accessToken")

        if (token) {
          if(!userData.accessToken) dispatch(userGetData(token))
        }

        if (changeNickname.success && userData?.accessToken) {
            dispatch(userGetData(userData.accessToken))
        }

        if (userDataXsolla.error) {
            sessionStorage.removeItem("accessToken")
            reset("signIn")
            setData({path1: "signIn", path2: "userData", data: {}})
        }
    }, [changeNickname.success, userDataXsolla.error])

    useEffect(() => {
        if (userData && Object.keys(userData).length) {
            if (!userData.nickname) {
                changeStep("step14")
            } else {
                changeStep("")
            }
        }
    }, [userData])

    useEffect(() => {
        if (!stepData.currentStep) {
            if (signUp.success) {
                dispatch(reset("signUp"))
            }
            setStepData(stepDataCopy)
        }
    }, [stepData.currentStep])

    useEffect(() => {
        const href = new URL(window.location.href).searchParams
        const token = href.get("token")
        if (token) {
            try {
                let decode = jwt_decode(token)

                if (decode) {
                    dispatch(userVerificationSocial(token))
                    navigate("/")
                }
            } catch (error) {
                navigate("/")
            }
        }

        if (userVerification.success) {
            if (userVerification.registration) {
                changeStep("step8")
            }
        }
    }, [userVerification.success])


    const fieldHandle = (e) => {
        let {name, value, type} = e.target
        const stepDataCopy = {...stepData}
        if (type !== "checkbox") {
            stepDataCopy.steps[stepDataCopy.currentStep].fields[name].value = value
            setStepData(stepDataCopy)
        } else {
            stepDataCopy.steps[stepDataCopy.currentStep].fields[name].value = !stepDataCopy.steps[stepDataCopy.currentStep].fields[name].value
            setStepData(stepDataCopy)
        }
    }

    const signOut = () => {
        sessionStorage.removeItem("accessToken")
        setMobileMenu({open: false, section: ""})
        dispatch(setData({path1: "signIn", path2: "userData", data: {}}))
        deactivate()
    }

    return (
        <React.Fragment>
            <header className={menuHeader}>
                <div className="navigation">
                    <div className="navigation_logo">
                        <div className="navigation_logo_block">
                            <Image onClick={() => navigate("/")} src={png.LogoKompete} alt="logo"/>
                        </div>
                    </div>
                    <div className="navigation_pages">
                        <ul>
                            {
                                navPaths.map((elm, i) =>
                                    <NavItem notLink={elm?.notLink} name={elm.name} path={elm.path} key={i}/>
                                )
                            }
                        </ul>
                    </div>
                    <div className="navigation_auth">
                        {
                            !mobileMenu.open &&
                            <UserLoginDisplay
                                signOut={signOut}
                                changeStep={changeStep}
                                SetMobileMenu={setMobileMenu}
                            />
                        }
                        <div className={`navigation_auth_btn-mobile ${mobileMenu.open ? "btn-close" : ""}`}
                             onClick={() => {
                                 !mobileMenu.open ? setMobileMenu({open: true, section: "menu"}) :
                                     setMobileMenu({open: false, section: ""})
                             }}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </header>
            {
                mobileMenu.open &&
                <MenuMobile
                    signOut={signOut}
                    setMobileMenu={setMobileMenu}
                    mobileMenu={mobileMenu}
                />
            }
            <AuthV2Steps
                stepData={stepData}
                setStepData={setStepData}
                changeStep={changeStep}
                fieldHandle={fieldHandle}
            />
            <LoadSpinnerAllPage show={userVerification.loading}/>
        </React.Fragment>
    )
}

export default Header