import React from "react"
import Image from "../../components/atoms/Image";
import {svg} from "../../assets/svg";
import "./style.scss"

const Links = () => {
    return (
        <div className="links">
                <div className="links_block">
                    <div className="links_block_logo">
                        <Image src={svg.Logo} alt="logo"/>
                    </div>
                    <LinkCart
                        text="Official website"
                        href="https://kompete.game"
                    />
                    <LinkCart
                        text="Kompete token chart"
                        href="https://www.coinscan.com/tokens/eth/kompete/"
                    />
                    <LinkCart
                        text="Buy kompete token (4% slippage)"
                        href="https://app.uniswap.org/#/swap?outputCurrency=0x1e0b2992079b620aa13a7c2e7c88d2e1e18e46e9"
                    />
                    <LinkCart
                        text="telegram"
                        href="https://t.me/KOMPETEgameportal"
                    />
                    <LinkCart
                        text="discord"
                        href="https://discord.gg/79PcMZEKar"
                    />
            </div>
        </div>
    )
}

const LinkCart = ({text, href}) => {
    return (
        <button>
            <a href={href} target="_blank" rel="noreferrer">{text}</a>
        </button>
    )
}

export default Links
