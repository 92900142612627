import React from "react";

import DownloadLayout from "../../layouts/DownloadLayout";
import Seo from "../../components/atoms/Seo";
import DownloadMain from "../../components/organisms/Download/DownloadMain";


const Download = () => {
    return (
        <DownloadLayout>
            <Seo
                title="Download - KOMPETE"
                description="Download KOMPETE on Windows. iOS, Android, Xbox, and Playstation are coming soon!"
            />
            
    
            <DownloadMain/>
        </DownloadLayout>
    )
}
export default Download

