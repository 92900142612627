import {ethers} from "ethers";
import ERC20ABI from "../../artifacts/contracts/ERC20.sol/ERC20.json";
import {formatUnits} from "ethers/lib/utils";

const options = {
    host: process.env.REACT_APP_HOST,
    xsolla_project_id: process.env.REACT_APP_XSOLLA_PROJECT_ID,
}

const userSignUp = async (data, thunkAPI) => {
    const response = await fetch(`${options.host}/v1/user/register`, {
        method: "POST",
        body: JSON.stringify(data)
    })

    if (response.status === 204 || response.status === 200) {
        return true
    } else {
        let result = await response.json()

        if (!Array.isArray(result)) {
            if (Array.isArray(result.error.details)) {
                return thunkAPI.rejectWithValue(result.error.details[0].message)
            } else {
                return thunkAPI.rejectWithValue(result.error.details.message)
            }
        } else {
            if (result[0]) {
                return thunkAPI.rejectWithValue(result[0].error.description)
            } else {
                return thunkAPI.rejectWithValue("Something went wrong")
            }
        }
    }
}

const userSignIn = (data, thunkAPI) => {
    return fetch(`https://login.xsolla.com/api/login?projectId=${options.xsolla_project_id}`, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    })
        .then(async (result) => {
            const data = await result.json()
            const {status} = result

            if (status === 200) {
                let url = data.login_url
                return new URL(url).searchParams.get("token")
            }

            if (status === 401) throw new Error("Account details incorrect. Please enter again")
            throw new Error(data.error.description)
        })
        .then((result) => {
            return userGetData(result, thunkAPI)
                .then((user) => {
                    if (!user.nickname) {
                        user.accessToken = result
                        return user
                    } else {
                        sessionStorage.setItem("accessToken", result)
                        return user
                    }
                })
        })
        .catch((error) => {
            return thunkAPI.rejectWithValue(error)
        })
}

const userRecoverPassword = async (data, thunkAPI) => {
    const response = await fetch(
        `https://login.xsolla.com/api/password/reset/request?projectId=${options.xsolla_project_id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })

    if (response.status === 204) {
        return true;
    } else {
        return thunkAPI.rejectWithValue("Something went wrong!")
    }
}

const userGetData = async (jwt, thunkAPI) => {
    return Promise.all([getUserDataFromXsolla(jwt, thunkAPI), getUserDataFromBack(jwt, thunkAPI)])
        .then((result) => {
            result[0].kompeteBalance = result[1].balance
            result[0].wallet = result[1].wallet
            result[0].accessToken = jwt
            return result[0]
        })
}

const getUserDataFromXsolla = (JWT, thunkAPI) => {
    return fetch("https://login.xsolla.com/api/users/me", {
        method: 'GET',
        headers: {
            Authorization: "Bearer " + JWT
        }
    })
        .then(async (result) => {
            if (result.status === 200) {
                return await result.json()
            } else {
                return thunkAPI.rejectWithValue(result.error.description)
            }
        })
}

const getUserDataFromBack = (JWT, thunkAPI) => {
    sessionStorage.removeItem("login")
    return fetch(`${options.host}/v1/user/auth/basic`, {
        method: 'POST',
        body: JSON.stringify({jwt: JWT})
    })
        .then(async (result) => {
            if (result.status === 200) {
                let data = await result.json()
                const {wallet} = data.success.user

                if (wallet) {
                    return {balance: await getUserKompeteTokenBalance(wallet), wallet}
                } else {
                    return {balance: 0, wallet}
                }
            } else {
                throw new Error("Something went wrong")
            }
        }).catch(()=>{
            throw new Error("Something went wrong")
        })
}

const userCheckNickname = (data, thunkAPI) => {
    const {nickname, accessToken} = data

    return fetch(`${options.host}/v1/user/nickname/change`, {
        method: "POST",
        body: JSON.stringify({nickname: nickname, jwt: accessToken})
    }).then((result) => {
        if (result.status === 200) {
            return fetch(`https://login.xsolla.com/api/users/search/by_nickname?nickname=${nickname}&limit=100`, {
                method: "GET",
                headers: {Authorization: `Bearer ${accessToken}`}
            })
                .then((result) => result.json())
                .then((result) => {
                    if (result.users.length) {
                        if (result.users.some((user) => user.nickname === nickname)) {
                            return thunkAPI.rejectWithValue("Nickname is taken! Please try again.")
                        } else {
                            return 200
                        }
                    } else {
                        return 200
                    }
                })
        }

        if (result.status === 422) {
            return thunkAPI.rejectWithValue("Invalid nickname")
        }

        return thunkAPI.rejectWithValue(result.error.description)
    })
}

const userChangeNickname = (data, thunkAPI) => {
    const {nickname, accessToken} = data

    fetch("https://login.xsolla.com/api/users/me", {
        method: "PATCH",
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({nickname: nickname})
    })
        .then(async (result) => {
            if (result.status !== 200) {
                let result = await result.json()

                return thunkAPI.rejectWithValue(result.error.description)
            }
            sessionStorage.setItem("accessToken", accessToken)

            return true
        })
}

const userVerificationSocial = (jwt, thunkAPI) => {
    sessionStorage.setItem("login","social")
    return fetch(`${options.host}/v1/user/auth/social`, {
        method: "POST",
        body: JSON.stringify({jwt: jwt})
    })
        .then(async (result) => {

            if (result.status === 200) {
                const data = await result.json()
                const {wallet} = data.success.user
                const kompeteBalance = wallet ? await getUserKompeteTokenBalance(wallet) : 0

                return getUserDataFromXsolla(jwt, thunkAPI)
                    .then((result) => {
                        result.kompeteBalance = kompeteBalance
                        result.wallet = wallet
                        result.accessToken = jwt
                        if (!result.nickname) {
                            return {type: "firstLogin", user: result}
                        } else {
                            sessionStorage.setItem("accessToken", jwt)
                            return {type: "firstLogin", user: result}
                        }
                    })
            }

            if (result.status === 422) {
                return {type: "registration", token: jwt}
            }
        })
}

const userSignUpSocial = (data, thunkAPI) => {
    const {fields, token} = data
    return fetch(`${options.host}/v1/user/register/social`, {
        method: "POST",
        body: JSON.stringify({...fields, jwt: token})
    })
        .then(async (result) => {
            if (result.status === 200) {
                return true
            } else {
                return thunkAPI.rejectWithValue("Something went wrong")
            }
        },)
}

const userAddWallet = (data, thunkAPI) => {
    const {wallet, token} = data

    return fetch(`${options.host}/v1/user/wallet/change`, {
        method: "POST",
        body: JSON.stringify({wallet: wallet, jwt: token})
    })
        .then(async (result) => {
            if (result.status === 200) {
                return wallet
            } else {
                return thunkAPI.rejectWithValue("Something went wrong")
            }
        })
}


const getUserKompeteTokenBalance = async (wallet) => {
    const alchemyProvider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_PROVIDER_MAINNET_URL)
    const tokenContractAddress = process.env.REACT_APP_KOMPETE_TOKEN_ADDRESS
    const contract = new ethers.Contract(tokenContractAddress, ERC20ABI, alchemyProvider);
    let balance = await contract.balanceOf(wallet);

    return formatUnits(balance, 10).split(".")[0]
}

export const authV2Service = {
    userSignUp,
    userSignIn,
    userRecoverPassword,
    userCheckNickname,
    userChangeNickname,
    userGetData,
    userVerificationSocial,
    userSignUpSocial,
    userAddWallet
}