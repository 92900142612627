import React from "react";
import classNames from "classnames";
import "./style.scss"

const NetworkButton = ({link, network, size}) => {
    const buttonType = classNames("network-button", network, size)

    return (
        <a
            href={link}
            rel="noreferrer"
            target="_blank"
            className={buttonType}
        >
            <span></span>
        </a>
    )
}

export default NetworkButton