import React, {useEffect, useState} from "react";
import {useWeb3React} from "@web3-react/core";
import {useDispatch, useSelector} from "react-redux";

import {userAddWallet, reset} from "../../../Store/AuthV2/AuthV2Slice";
import {web3Utils} from "../../../web3/utils";
import {connectors} from "../../../web3/connectors";

import LoadSpinnerAllPage from "../../atoms/LoadSpinner/LoadSpinnerAllPage";
import ProfileStepTwo from "./ProfileStepTwo";
import ProfileStepThree from "./ProfileStepThree";
import ProcessModal from "../../molecules/TxhModals/ProcessModal";
import WalletsModal from "../../molecules/WalletModal";

const ProfileSteps = ({currentStep, setCurrentStep}) => {
    const {deactivate, activate, account, active, connector, chainId, library} = useWeb3React()
    const {accessToken} = useSelector(state => state.authV2.signIn.userData)
    const {success, error, loading} = useSelector(state => state.authV2.addWallet)
    const [isConnectWallet, setIsConnectWallet] = useState({status: false, provider: ""})
    const dispatch = useDispatch()

    useEffect(() => {
        if (success) {
            setCurrentStep("profileStep2")
            dispatch(reset("addWallet"))
        }

        if (error) {
            setCurrentStep("profileStep3")
            dispatch(reset("addWallet"))
        }
    }, [success, error])

    useEffect(() => {
        if (active && account && isConnectWallet.status) {
            setCurrentStep("profileStep4")
            web3Utils.switchWalletToEth(library, chainId).then(result => {
                if (result) {
                    web3Utils.walletSignature(connector)
                        .then((result) => {
                            const {isVerify} = result
                            if (isVerify) {
                                dispatch(userAddWallet({wallet: account, token: accessToken}))
                                deactivate()
                            } else {
                                deactivate()
                                setCurrentStep("profileStep3")
                            }
                        })
                    setIsConnectWallet({status: false, provider: ""})
                } else {
                    deactivate()
                    setCurrentStep("profileStep3")
                }
            })

        }
    }, [active, account])

    const connectWallet = (provider) => {
        activate(connectors[provider])
        setIsConnectWallet({status: true, provider: provider})
    }

    return (
        <React.Fragment>
            {
                {
                    "profileStep1":
                        <WalletsModal
                            show={currentStep === "profileStep1"}
                            closeClick={setCurrentStep}
                            buttonClick={setCurrentStep}
                            connect={connectWallet}
                        />,
                    "profileStep2":
                        <ProfileStepTwo
                            show={currentStep === "profileStep2"}
                            changeStep={setCurrentStep}
                        />,
                    "profileStep3":
                        <ProfileStepThree
                            show={currentStep === "profileStep3"}
                            changeStep={setCurrentStep}
                        />,
                    "profileStep4":
                        <ProcessModal
                            show={currentStep === "profileStep4"}
                            text1="Waiting for signature..."
                            text2="After wallet approval, your transaction will be finished shortly."
                        />
                }
                    [currentStep]
            }
            <LoadSpinnerAllPage show={loading}/>
        </React.Fragment>
    )
}

export default ProfileSteps