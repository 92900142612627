import {ethers} from "ethers";
import {createOrderWithSignature, web3Utils} from "../../../../web3/utils";
import {ZERO_BYTES32} from "@modernize-games/nft-sdk";
import {parseUnits} from "ethers/lib/utils";

const mintOrderForCosmetic = async ({connector, price, tokenId, quantity, account, hashCallBack}) => {
    const {marketplace} = await web3Utils.getConfiguration()
    const parsePrice = parseUnits(price.toString(), 18)

    const getProvider = await connector.getProvider()
    const provider = new ethers.providers.Web3Provider(getProvider)
    const signer = provider.getSigner()

    const sellOrderWithSignature = await createOrderWithSignature({
        token: "eth",
        price,
        tokenId,
        amount: quantity,
        orderSide: "Sell",
        admin: true
    })

    const buyOrderWithSignature = await createOrderWithSignature({
        connector,
        token: "eth",
        price,
        tokenId,
        amount: quantity,
        account,
        orderSide: "Buy",
    })

    const gasLimit = (
        await marketplace.estimateGas.atomicMatch_(
            buyOrderWithSignature.order,
            buyOrderWithSignature.signature,
            sellOrderWithSignature.order,
            sellOrderWithSignature.signature,
            ZERO_BYTES32,
            {
                value: parsePrice,
            },
        )
    )
        .mul(110)
        .div(100)

    const tx = await marketplace
        .connect(signer)
        .atomicMatch_(
            buyOrderWithSignature.order,
            buyOrderWithSignature.signature,
            sellOrderWithSignature.order,
            sellOrderWithSignature.signature,
            ZERO_BYTES32,
            {
                gasLimit,
                value: parsePrice,
            },
        )

    hashCallBack(tx)

    return tx.wait().then((result) => result.status)
}

export const divineUtils = {
    mintOrderForCosmetic
}