import React from "react";
import {useNavigate} from "react-router-dom"

import Title from "../../atoms/TitleTemplate";
import Image from "../../atoms/Image";
import Button from "../../atoms/ButtonV3";

import {png} from "../../../assets/png";
import "./style.scss"

const EconomyYouPay = () => {
    const navigate = useNavigate()

    return (
        <section className="economy_you-pay">
            <Title underline={true}>You pay your way at the <span>shop & ticket</span></Title>
            <div className="economy_you-pay_options container">
                <OptionsItem
                    title="voolah"
                    img={png.VoolahEco}
                    onClick={() => navigate("/buy-kompete-token")}
                >
                    KOMPETE’s in-game currency for players who want to use fiat to pay for their cosmetics at the shop. Available on
                    all marketplaces and the KOMPETE website.
                </OptionsItem>
                <OptionsItem
                    title="ethereum"
                    img={png.EthereumEco}
                    onClick={() => navigate("/shop")}
                >
                    The KOMPETE Shop is web3 compatible with the Ethereum blockchain, allowing for full ownership of cosmetics.
                    Available on the KOMPETE website only.
                </OptionsItem>
            </div>
        </section>
    )
}
export default EconomyYouPay

const OptionsItem = ({img, title, onClick, children}) => {
    return (
        <div className="economy_you-pay_options_item">
            <div className="economy_you-pay_options_item_img-block">
                <Image src={img} alt={title}/>
            </div>
            <div className="economy_you-pay_options_item_content">
                <h2>{title}</h2>
                <p>{children}</p>
            </div>
            <Button view="secondary" onClick={onClick}>
                get {title}
            </Button>
        </div>
    )
}