import React, {useEffect} from "react"
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

import {setBackgroundClass} from "../../Store/Global/globalSlice"

import DefaultLayout from "../../layouts/DefaultLayout";
import Button from "../../components/atoms/ButtonV3";
import Image from "../../components/atoms/Image";
import useWindowSize from "../../Hooks/useWindowSize";

import {png} from "../../assets/png";
import "./style.scss"

const PageNotFound = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {width} = useWindowSize()

    useEffect(() => {
        dispatch(setBackgroundClass("fire-back"))

        return (() => {
            dispatch(setBackgroundClass(""))
        })
    }, [])

    return (
        <DefaultLayout isFollowUsShow={false}>
            <div className="page-not-found-container">
                <div className="page-not-found-container_block">
                    <div className="page-not-found-container_block_img">
                        <Image alt="404" src={png.NotFound}/>
                    </div>
                    <h1 className="page-not-found-container_block_title">Page not found</h1>
                    <p className="page-not-found-container_block_text">Sorry! The page you’re trying to access can’t be found.</p>
                    <Button
                        view="secondary"
                        size={width < 480 ? "sm" : "lg"}
                        onClick={() => navigate("/")}
                    >
                        Back to Home
                    </Button>
                </div>
            </div>
        </DefaultLayout>
    )
}
export default PageNotFound