import React from "react"
import {useSelector} from "react-redux";
import classNames from "classnames";
import "./style.scss"

const Wrapper = ({children}) => {
    const {backgroundImgClass} = useSelector(state => state.global)

    const wrapperStyle = classNames("wrapper", backgroundImgClass)

    return (
        <div className={wrapperStyle}>
            {children}
        </div>
    )
}
export default Wrapper