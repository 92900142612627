import React, {useEffect} from "react"
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import useWindowSize from "../../../../Hooks/useWindowSize";
import {useWeb3React} from "@web3-react/core";
import classNames from "classnames";

import NavItem from "../ResuableComponents/NavItem";
import NetworkButton from "../../../../components/atoms/NetworkButton";

import {formatBalance} from "../../../../Utils/utils";
import navPaths from "../navPathData.json"
import networkData from "../../../../components/atoms/NetworkButton/networkData.json"

import {png} from "../../../../assets/png";
import {svg} from "../../../../assets/svg";
import "./../style.scss"

const MenuMobile = ({mobileMenu, signOut, setMobileMenu}) => {
    const {kompeteBalance, ethBalance, nickname, picture, wallet} = useSelector(state => state.authV2.signIn.userData)
    const {account, active} = useWeb3React()
    const {width} = useWindowSize()

    const navigationMobile = classNames("navigation-mobile", {"mobile-menu": mobileMenu.open && mobileMenu.section === "menu"})
    const noConnectedWallet = classNames({"no-wallet": !account && !active})
    const noRegisteredWallet = classNames({"no-wallet": !wallet})
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const contentStyle = classNames("navigation-mobile_content",{safari:isSafari})

    useEffect(() => {
        if (width > 991) {
            setMobileMenu({open: false, section: ""})
        }
    }, [width])

    useEffect(() => {
        document.body.style.overflow = "hidden"
        document.body.style.paddingRight = "7px"
        return () => {
            document.body.style.overflow = "unset"
            document.body.style.paddingRight = "0"
        }
    }, [])

    return (
        <div className={navigationMobile}>
            <div className={contentStyle}>
                {
                    {
                        "menu":
                            <div className="navigation-mobile_content_menu">
                                <ul>
                                    {
                                        navPaths.map((elm, i) =>
                                            <NavItem notLink={elm?.notLink} name={elm.name} path={elm.path} key={i}/>
                                        )
                                    }
                                </ul>
                            </div>,
                        "userMenu":
                            <React.Fragment>
                                <div className="navigation-mobile_content_user-menu">
                                    <h3>{nickname}</h3>
                                    <img alt="avatar" src={picture ? picture : png.NewAvatar}/>
                                    <div className="navigation-mobile_content_user-menu_balance">
                                        <div className={noConnectedWallet}>
                                            <img alt="eth" src={svg.Eth}/>
                                            <span>{formatBalance(ethBalance ? ethBalance : 0)}</span>
                                        </div>
                                        <div className={noRegisteredWallet}>
                                            <img alt="kompete-token" src={png.T1}/>
                                            <span>{formatBalance(kompeteBalance)}</span>
                                        </div>
                                    </div>
                                    <div className="navigation-mobile_content_user-menu_options">
                                        <Link to="/profile">Profile</Link>
                                        <button onClick={() => signOut()}>Logout</button>
                                    </div>
                                </div>
                            </React.Fragment>
                    }[mobileMenu.section]
                }
                <div className="navigation-mobile_content_social">
                    {
                        networkData.map((elm, i) =>
                            <NetworkButton
                                key={i}
                                network={elm.network}
                                link={elm.link}
                                size="sm"
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default MenuMobile
