import React from "react";

const ReactPlayer = ({src,title}) => {
    return (
        <iframe
            src={src}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}} title={title}>
        </iframe>
    )
}
export default ReactPlayer