import React from "react";
import Title from "../../../atoms/TitleTemplate";
import Image from "../../../atoms/Image";
import UnderLine from "../../../atoms/UnderLine";
import Button from "../../../atoms/ButtonV3";

import {png} from "../../../../assets/png";
import "./style.scss"

const HowToBuyTokenThree = ({changeStep}) => {
    return (
        <section className="how-to-buy-token_three">
            <div className="container">
                <Title underline={true}>Success!</Title>
                <div className="how-to-buy-token_three_success">
                    <div className="how-to-buy-token_three_success_block-two">
                        <Image alt="kompete token" src={png.TokenFloating}/>
                    </div>
                    <div className="how-to-buy-token_three_success_block-one">
                        <h2 className="how-to-buy-token_three_success_block-one_title">
                            Make sure your<br/>
                            <span> tokenS Are Accessible!</span>
                        </h2>
                        <UnderLine/>
                        <p className="how-to-buy-token_three_success_block-one_text">
                            To use your KOMPETE Tokens, you’ll want to make sure they are in the wallet that’s added to
                            your account! You can add a wallet in the Profile section when you’re logged into your
                            KOMPETE account here on the website.
                        </p>
                        <Button onClick={() => changeStep("step1")} view="secondary">Login to website</Button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowToBuyTokenThree