import React from "react";
import classNames from "classnames";

import Title from "../../atoms/TitleTemplate";
import Image from "../../atoms/Image";

import {png} from "../../../assets/png";
import "./style.scss"

const EconomyCollecting = () => {
    return (
        <section className="economy_collecting">
            <Title underline={true}>Collecting <span>Cosmetics</span></Title>
            <ItemBlock
                title="Limited Time Shop"
                className="item-three"
                image={png.HeroIndividual3}
            >
                The Limited Time Shop is your go-to shop to stay drippy on Mahzookoo Island. The shop options stay rotating & sometimes
                include items that won't ever be sold again! Time is ticking, so don't be a bot and grab some gear.
            </ItemBlock>
            <ItemBlock
                title="Divine Shop"
                className="item-four"
                image={png.HeroIndividual4}
                reverse={true}
                background={true}
            >
                The Divine Shop is an NFT-only shop. It's ultra-exclusive, limited in quantity, and only sells cosmetics of the highest
                rarity, Divine. You'll use Ethereum to scoop up these bad boys. Act fast when we drop these cause once it's gone, it's
                gone. Get elevated.
            </ItemBlock>
            <ItemBlock
                title="Fame"
                className="item-one"
                image={png.HeroIndividual1}
            >
                FAME is a free progression system for players to earn rewards. It resets every three months. As you level up, you'll
                unlock exclusive rewards to that Fame cycle. The early levels are a breeze, but the further you go, the more difficult
                it becomes. Top rewards are limited and only reserved for KOMPETE Phenoms!
            </ItemBlock>
            <ItemBlock
                title="Ticket"
                className="item-two"
                image={png.HeroIndividual2}
                reverse={true}
                background={true}
            >
                The Ticket is a paid progression system for players to earn rewards and bonuses. A new Ticket starts on the 1st of each
                month! With each Ticket, you'll grind for exclusive rewards only available for a limited time during that Ticket
                season. As you progress through, each level gets a little harder. Get ready to grind, or you might miss out on
                unlocking everything!
            </ItemBlock>
            <ItemBlock
                title="Bazaar"
                className="item-five"
                image={png.HeroIndividual5}
            >
                The Bazaar is a peer-to-peer market allowing players to buy and sell cosmetics with each other! Cosmetics find their
                true value in the Bazaar. If you wanna sell your cosmetics to cash out or scoop up the goods you missed out on, then
                this is the spot! Remember, the Bazaar is powered exclusively by KOMPETE Token!
            </ItemBlock>
        </section>
    )
}

export default EconomyCollecting

const ItemBlock = ({title, image, className, reverse, background, children}) => {
    const howItemStyle = classNames("economy_collecting_item", "container", className, {reverse: reverse})
    const homeItemParentStyle = classNames({"red-back": background})

    return (
        <div className={homeItemParentStyle}>
            <div className={howItemStyle}>
                <div>
                    <Image src={image} alt="hero"/>
                </div>
                <div>
                    <h3>{title}</h3>
                    <p>{children}</p>
                </div>
            </div>
        </div>
    )
}