import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useWeb3React} from "@web3-react/core";

import {userSignUp, setData, userSignUpSocial} from "../../../../Store/AuthV2/AuthV2Slice";
import {connectors} from "../../../../web3/connectors";
import {web3Utils} from "../../../../web3/utils";

import StepSignIn from "./Steps/1_StepSignIn";
import StepSignInForm from "./Steps/2_StepSignInForm";
import StepRecoverPassword from "./Steps/3_StepRecoverPassword";
import StepSignUp from "./Steps/4_StepSignUp";
import StepSignUpForm from "./Steps/5_StepSignUpForm";
import StepIsConnectWallet from "./Steps/6_StepIsConnectWallet";
import StepSignUpSuccess from "./Steps/7_StepSignUpSuccess";
import StepNineWallet from "./Steps/9_StepWallets";
import StepWalletSuccess from "./Steps/11_StepWalletSuccess";
import StepWalletReject from "./Steps/12_StepWalletReject";
import StepRecoverPasswordSuccess from "./Steps/13_StepRecoverPasswordSuccess";
import StepNickname from "./Steps/14_StepAddNickname";
import LoadSpinnerAllPage from "../../../../components/atoms/LoadSpinner/LoadSpinnerAllPage";
import ProcessModal from "../../../../components/molecules/TxhModals/ProcessModal";

const AuthV2Steps = ({stepData, setStepData, changeStep, fieldHandle}) => {
    const {deactivate, activate, active, account, connector, chainId, library} = useWeb3React()
    const dispatch = useDispatch()
    const {signUp} = useSelector(state => state.authV2)
    const {signUpSocial} = useSelector(state => state.authV2)
    const {userData} = useSelector(state => state.authV2.signIn)
    const {email, password, checkbox1, checkbox2, checkbox3} = stepData.steps.step5.fields
    const {fields} = stepData.steps.step8
    const [isConnectWallet, setIsConnectWallet] = useState({status: false, provider: ""})

    useEffect(() => {
        if (active && account && isConnectWallet.status) {
            changeStep("step10")
            web3Utils.switchWalletToEth(library, chainId).then(result => {
                if (result) {
                    web3Utils.walletSignature(connector)
                        .then((result) => {
                            const {isVerify} = result
                            if (isVerify) {
                                changeStep("step11")
                            } else {
                                changeStep("step12")
                                deactivate()
                            }
                        })
                    setIsConnectWallet({status: false, provider: ""})
                } else {
                    changeStep("step12")
                    deactivate()
                }
            })
        }
    }, [active, account])

    const checkMail = () => {
        const checkTheMailRegexp = /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!mail.ru)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/g

        if (!stepData.currentEmail.match(checkTheMailRegexp)) {
            window.open("https://" + stepData.currentEmail.split("@")[1])
            changeStep("")
        } else {
            changeStep("")
        }
    }

    const checkFields = (field) => {
        const {currentStep} = stepData

        for (let field in stepData.steps[currentStep].fields) {
            stepData.steps[currentStep].fields[field].validation = !stepData.steps[currentStep].fields[field].value
        }

        if (field) {
            stepData.steps[currentStep].fields[field].validation = true
        }

        setStepData(stepData)
    }

    const registerUserDispatch = (wallet, registerType) => {
        if (registerType === "regular") {
            let userInfo = {
                email: email.value,
                password: password.value,
                terms: checkbox1.value,
                offers: checkbox2.value,
                is13years: checkbox3.value,
                wallet: wallet ? wallet : null
            }

            dispatch(userSignUp(userInfo))
            dispatch(setData({path1: "signUp", path2: "fieldsData", data: userInfo}))
        }

        if (registerType === "social") {
            let userInfo = {
                terms: fields.checkbox1.value,
                offers: fields.checkbox2.value,
                is13years: fields.checkbox3.value,
                wallet: wallet ? wallet : null
            }

            dispatch(userSignUpSocial({fields: userInfo, token: userData?.accessToken}))
        }
    }

    const connectWallet = (provider) => {
        activate(connectors[provider])
        setIsConnectWallet({status: true, provider: provider})
    }

    return (
        <React.Fragment>
            {
                {
                    "step1":
                        <StepSignIn
                            show={stepData.currentStep === "step1"}
                            setStepData={setStepData}
                            changeStep={changeStep}
                        />,
                    "step2":
                        <StepSignInForm
                            show={stepData.currentStep === "step2"}
                            stepData={stepData}
                            setStepData={setStepData}
                            changeStep={changeStep}
                            fieldHandle={fieldHandle}
                            checkFields={checkFields}
                        />,
                    "step3":
                        <StepRecoverPassword
                            show={stepData.currentStep === "step3"}
                            stepData={stepData}
                            setStepData={setStepData}
                            changeStep={changeStep}
                            fieldHandle={fieldHandle}
                        />,
                    "step4":
                        <StepSignUp
                            show={stepData.currentStep === "step4"}
                            changeStep={changeStep}
                        />,
                    "step5":
                        <StepSignUpForm
                            show={stepData.currentStep === "step5"}
                            changeStep={changeStep}
                            stepData={stepData}
                            setStepData={setStepData}
                            fieldHandle={fieldHandle}
                            checkFields={checkFields}
                        />,
                    "step6":
                        <StepIsConnectWallet
                            show={stepData.currentStep === "step6"}
                            changeStep={changeStep}
                            registerUserDispatch={registerUserDispatch}
                            setStepData={setStepData}
                        />,
                    "step7":
                        <StepSignUpSuccess
                            show={stepData.currentStep === "step7"}
                            changeStep={changeStep}
                        />,
                    "step8":
                        <StepSignUpForm
                            show={stepData.currentStep === "step8"}
                            changeStep={changeStep}
                            stepData={stepData}
                            fieldHandle={fieldHandle}
                        />,
                    "step9":
                        <StepNineWallet
                            show={stepData.currentStep === "step9"}
                            changeStep={changeStep}
                            registerUserDispatch={registerUserDispatch}
                            connect={connectWallet}
                        />,
                    "step10":
                        <ProcessModal
                            show={stepData.currentStep === "step10"}
                            text1="Waiting for signature..."
                            text2="After wallet approval, your transaction will be finished shortly."
                        />,
                    "step11":
                        <StepWalletSuccess
                            show={stepData.currentStep === "step11"}
                            changeStep={changeStep}
                            registerUserDispatch={registerUserDispatch}
                        />,
                    "step12":
                        <StepWalletReject
                            show={stepData.currentStep === "step12"}
                            changeStep={changeStep}
                        />,
                    "step13":
                        <StepRecoverPasswordSuccess
                            show={stepData.currentStep === "step13"}
                            changeStep={changeStep}
                            stepData={stepData}
                            checkMail={checkMail}
                        />,
                    "step14":
                        <StepNickname
                            show={stepData.currentStep === "step14"}
                            stepData={stepData}
                            changeStep={changeStep}
                        />
                }[stepData.currentStep]
            }
            <LoadSpinnerAllPage show={signUp.loading}/>
            <LoadSpinnerAllPage show={signUpSocial.loading}/>
        </React.Fragment>
    )
}
export default AuthV2Steps