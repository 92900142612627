import React from "react";
import {useNavigate} from "react-router-dom"
import Title from "../../atoms/TitleTemplate";
import Image from "../../atoms/Image";
import Button from "../../atoms/ButtonV3";

import {png} from "../../../assets/png";
import "./style.scss"

const EconomyBuySell = () => {
    const navigate = useNavigate()

    return (
        <section className="economy_buy-sell container">
            <Title underline={true}><span>Buy & Sell cosmetics</span> using kompete token</Title>
            <div className="economy_buy-sell_content">
                <div className="economy_buy-sell_content_img-block">
                    <Image alt="kompete-token" src={png.KompeteTokenStacked}/>
                </div>
                <div className="economy_buy-sell_content_info">
                    <h3>Transact with other players!</h3>
                    <p>Convert your cosmetics to an NFT and list those cosmetics on The Bazaar for KOMPETE Token! If another player
                        purchases your cosmetic, then you can cash out with KOMPETE Token!</p>
                    <Button
                        view="secondary"
                        onClick={() => navigate("/buy-kompete-token")}
                    >
                        Get kompete token
                    </Button>
                </div>
            </div>
        </section>
    )
}
export default EconomyBuySell