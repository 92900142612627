import React from "react";
import {HelmetProvider} from "react-helmet-async";
import Routers from "./Routers";

function App() {
    return (
        <React.Fragment>
            <HelmetProvider>
                <Routers/>
            </HelmetProvider>
        </React.Fragment>
    );
}

export default App;
